/**
InputSwitchWithLabel
Input text field Component with field error message
@functions:InputSwitchWithLabelComponent
*/
// Core react dependencies
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

/** UI components **/
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { AppSwitch } from '@coreui/react'

// Custom modules/components
import { FormFieldErrorText } from './FormFieldErrorText';

InputSwitchWithLabelComponent.propTypes = {
    name: PropTypes.string,
    id: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    color: PropTypes.string,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    errorMessages: PropTypes.array,
    inline: PropTypes.bool,
    labelcolspace: PropTypes.string,
    switchcolspace: PropTypes.string,
    showMarginBottom: PropTypes.bool,
    labelClassName: PropTypes.string
}

InputSwitchWithLabelComponent.defaultProps = {
  errorMessages: [],
  checked: false,
  inline: false,
  selectedValue: null,
  dataOn: null,
  dataOff: null,
  labelcolspace:null,
  switchcolspace:null,
  showMarginBottom:true,
  labelClassName:''
}

function InputSwitchWithLabelComponent(props) {
  const {
    name,
    id,
    label,
    value,
    color,
    checked,
    selectedValue,
    onChange,
    errorMessages,
    inline,
    dataOn,
    dataOff,
    labelcolspace,
    switchcolspace,
    showMarginBottom,
    labelClassName
  } = props;
  return (
    <div
      className={classnames({ 'mb-3': showMarginBottom})}
      >
      {!showMarginBottom &&
        <Row>
          <Col xs="8" md={label !== ''?(labelcolspace !== null?labelcolspace:'3'):'12'}>
            <Label htmlFor={name} className={labelClassName}>{label}:</Label>
          </Col>
          <Col xs="4" md={label !== ''?(switchcolspace !== null?switchcolspace:'9'):'12'} className="text-left">
            <AppSwitch
              className={'mx-1'}
              variant={'pill'}
              color={color}
              label
              dataOn={(dataOn !== null)?dataOn:'\u2713'}
              dataOff={(dataOff !== null)?dataOff:'\u2715'}
              name={name}
              value={value}
              checked={checked}
              onChange={onChange}
            />

            &nbsp;
            {(selectedValue !== null) &&
            <span className="switch-selected-value">{selectedValue}</span>
            }
            <FormFieldErrorText messages={errorMessages} field={id}  />
          </Col>
        </Row>
      }
      {showMarginBottom && inline &&
        <FormGroup row>
          <Col xs="12" md={label !== ''?(labelcolspace !== null?labelcolspace:'3'):'12'}>
            <Label htmlFor={name} className={labelClassName}>{label}</Label>
          </Col>
          <Col xs="12" md={label !== ''?(switchcolspace !== null?switchcolspace:'9'):'12'}>
            <AppSwitch
              className={'mx-1'}
              variant={'pill'}
              color={color}
              label
              dataOn={(dataOn !== null)?dataOn:'\u2713'}
              dataOff={(dataOff !== null)?dataOff:'\u2715'}
              name={name}
              value={value}
              checked={checked}
              onChange={onChange}
            />

            &nbsp;
            {(selectedValue !== null) &&
            <span className="switch-selected-value">{selectedValue}</span>
            }
            <FormFieldErrorText messages={errorMessages} field={id}  />
          </Col>
        </FormGroup>
      }
      {showMarginBottom && !inline &&
        <FormGroup>
          <Label htmlFor={name} className={labelClassName}>{label}</Label>
          <AppSwitch
            className={'mx-1'}
            variant={'pill'}
            color={color}
            outline={'alt'}
            label
            dataOn={'\u2713'}
            dataOff={'\u2715'}
            name={name}
            value={value}
            checked={checked}
            onChange={onChange}
          />
          <FormFieldErrorText messages={errorMessages} field={id}  />
        </FormGroup>
      }
    </div>
  );
}

export {InputSwitchWithLabelComponent as InputSwitchWithLabel};
