/**
Attribute options
*/

export const DISPLAY_ALIGNMENT =
  {
    'full_col':[
      {
        key:"left"
      },
      {
        key:"right"
      },
      {
        key:"center"
      }
    ],
    'split_col':[
      {
        key:"left_left"
      },
      {
        key:"right_left"
      },
      {
        key:"left_right"
      },
      {
        key:"right_right"
      },
      {
        key:"center_left"
      },
      {
        key:"center_right"
      },
      {
        key:"center_center"
      },
      {
        key:"left_center"
      },
      {
        key:"right_center"
      }
    ]
  };

export const DISPLAY_TYPE = [
  {
    key:"full_col"
  },
  {
    key:"split_col"
  }
]

export const EXCLUDE_SELECT_FIELD = process.env.REACT_APP_EXCLUDE_SELECT_FIELD
