/**
HttpMessageBar
Component to display the success and error messaages from API
@functions:HttpErrorBar
*/

// Core react dependencies
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// UI components
import { UncontrolledAlert } from 'reactstrap';

// Translation
import { getTranslate, getActiveLanguage, getLanguages } from 'react-localize-redux';



class HttpMessageBar extends React.Component {
    static propTypes = {
      alert: PropTypes.object,
    }
    static defaultProps = {
      alert: undefined
    }
    getTranslatedMessage = (message) =>{
      const {alert, translate} = this.props;
      if(alert.status === '500')
       return translate(message);
      else
       return message;
    }
    render() {
      const {alert, children, show_detail} = this.props;

      if(alert && alert.type === 'success')
      return (<UncontrolledAlert color="success">
            <i className="fas fa-check-circle"></i>&nbsp;
            {this.getTranslatedMessage(alert.message)}
          </UncontrolledAlert>)
      else if(alert && alert.type === 'error' &&  show_detail === true && alert.errors)
        return (<UncontrolledAlert color="danger">
              <div>
                <i className="fas fa-exclamation-circle"></i>&nbsp;
                {this.getTranslatedMessage(alert.message)}
              </div>
              <div>
              {
                Object.keys(alert.errors).map(error_field =>{
                  return(
                  alert.errors[error_field].map((error, errorkey)=>{
                    return(<div key={errorkey}>{error.description}</div>)
                  })
                  )
                })
              }
              </div>
            </UncontrolledAlert>)
      else if(alert && alert.type === 'error' &&  alert.translated === true && alert.message !== undefined)
        return (<UncontrolledAlert color="danger">
              <i className="fas fa-exclamation-circle"></i>&nbsp;
              <span>
                {this.getTranslatedMessage(alert.message)}
              </span>
              {children}
            </UncontrolledAlert>)
      else if(alert && alert.type === 'error' && alert.message !== undefined && alert.description !== undefined)
      return (<UncontrolledAlert color="danger">
            <p>
              <i className="fas fa-exclamation-circle"></i>&nbsp;
              <strong>{alert.message}</strong>
            </p>
            {this.getTranslatedMessage(alert.description)}
          </UncontrolledAlert>)
      else if(alert && alert.type === 'error' && alert.description !== undefined)
      return (<UncontrolledAlert color="danger">
            <i className="fas fa-exclamation-circle"></i>&nbsp;
            {this.getTranslatedMessage(alert.description)}
          </UncontrolledAlert>)
      else if(alert && alert.type === 'error' && alert.message !== undefined)
      return (<UncontrolledAlert color="danger">
            <div>
              <i className="fas fa-exclamation-circle"></i>&nbsp;
              {this.getTranslatedMessage(alert.message)}
            </div>
            {alert.errors &&
            <div>
            {
              Object.keys(alert.errors).map(error_field =>{
                return(
                alert.errors[error_field].map((error, errorkey)=>{
                  return(<div key={errorkey}>{error.description}</div>)
                })
                )
              })
            }
            </div>
            }
          </UncontrolledAlert>)
      else
      return null
    }
}


const mapStateToProps = state => (
  {
    languages: getLanguages(state.localize),
    translate: getTranslate(state.localize),
    activeLanguage: getActiveLanguage(state.localize).code,
  }
);
HttpMessageBar = connect(mapStateToProps)(HttpMessageBar)
export { HttpMessageBar };
