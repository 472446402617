
import { MEDIA_IMAGE_MAX_WIDTH, MEDIA_IMAGE_MAX_HEIGHT } from '../_config';

// to resize image on upload
export function resizeImage(files, onResized){
    // from an input element
    var file = files[0];
    var img = document.createElement("img");
    img.src = URL.createObjectURL(file);
    img.onload=()=>{
      var width = img.width;
      var height = img.height;

      if (width > height) {
        if (width > MEDIA_IMAGE_MAX_WIDTH) {
          height *= MEDIA_IMAGE_MAX_WIDTH / width;
          width = MEDIA_IMAGE_MAX_WIDTH;
        }
      } else {
        if (height > MEDIA_IMAGE_MAX_HEIGHT) {
          width *= MEDIA_IMAGE_MAX_HEIGHT / height;
          height = MEDIA_IMAGE_MAX_HEIGHT;
        }
      }
      //Draw the resized image
      var canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, width, height);

      var dataurl = canvas.toDataURL("image/png");
      onResized(dataurl);
    }
};
