// Core react libs
import React from 'react';
import PropTypes from 'prop-types';

//UI components
import { Row, Col  } from 'reactstrap';


// Local custom components/libs
import {
   InputTimeFieldWithLabel,
   InputSwitchWithLabel
 } from '../../_components/form'

 TimeIntervalFieldComponent.propTypes = {
     time_from:PropTypes.string,
     time_to:PropTypes.string,
     handleToggleClosed: PropTypes.func,
     handleTimeChange:PropTypes.func
 }

 TimeIntervalFieldComponent.defaultProps = {
   time_from:null,
   time_to:null,
   className:'',
 }

 function TimeIntervalFieldComponent(props) {
   const {
     time_from,
     time_to,
     handleToggleClosed,
     handleTimeChange,
     translate
   } = props;


    let closed = ((time_from === null
    && time_to === null) ||
    (time_from === ""
    && time_to === ""));

    return (
        <div>
          <Row>
          <Col xs="12">
            <InputSwitchWithLabel
              name="closed"
              id="closed"
              color={'success'}
              checked={closed?true:false}
              onChange={handleToggleClosed}
              label={translate('Closed')}
              inline={true}
              selectedValue={(closed)?translate('Yes'):translate('No')}
             />
          </Col>
          </Row>
          {!closed &&
          <Row>
          <Col xs="12">
              <InputTimeFieldWithLabel
                onChange={handleTimeChange}
                id="time_from"
                name ="time_from"
                label={translate('From')}
                value={time_from}
                inline={true}
               />
               <InputTimeFieldWithLabel
                 onChange={handleTimeChange}
                 id="time_to"
                 name ="time_to"
                 label={translate('To')}
                 value={time_to}
                 inline={true}
                />
          </Col>
          </Row>
          }
        </div>
    );
}

export {TimeIntervalFieldComponent as TimeIntervalField};
