/**
Image
Img component with place holder
*/

// Core react dependencies
import React from 'react';
import PropTypes from 'prop-types';
import Img from 'react-image'

ImageComponent.propTypes = {
    src: PropTypes.string,
    className: PropTypes.string
}

ImageComponent.defaultProps = {
}

function ImageComponent(props) {
  const {src, className, ...attributes} = props;
  return(
      <Img
        src={src}
        className={className}
        {...attributes}
        loader={
          <div
          className={className+ " img-loader"}
          {...attributes}>
          </div>
        }
        unloader={
          <div  className={className+ " img-loader"}
          {...attributes}>
          </div>
        }
      />
  );
}


export {ImageComponent as Img};
