/**
DefaultFooter
The footer of the container
*/

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Translation
import { getActiveLanguage } from 'react-localize-redux';

// Custom components/functions
import { urlConfig } from '../../_config';


const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class DefaultFooter extends Component {
  render() {

    // eslint-disable-next-line
    const { activeLanguage, children, ...attributes } = this.props;

    return (
      <React.Fragment>
        <span className="text-secondary"><a href={`${urlConfig.BASE_URL}/${activeLanguage}`}>{process.env.REACT_APP_FOOTER_TEXT}</a> &copy; 2018 Bigfive Edition SAS.</span>
      </React.Fragment>
    );
  }
}

DefaultFooter.propTypes = propTypes;
DefaultFooter.defaultProps = defaultProps;

const mapStateToProps = state => (
  {
    activeLanguage: getActiveLanguage(state.localize).code,
  }
);
DefaultFooter = connect(mapStateToProps)(DefaultFooter);
export default DefaultFooter;
