// Creating store for saving the state

import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { middleware as reduxPackMiddleware } from 'redux-pack';
import { createLogger } from 'redux-logger';
import rootReducer from '../_services/reducers';
import HttpRequest from '../_services/http/request';

// To hide the console log for redux actions
let DEBUG = 0;
if((process.env.NODE_ENV === 'development'))
  DEBUG = 1;
if((process.env.NODE_ENV === 'production'))
  DEBUG = 0;

const api = new HttpRequest();
const loggerMiddleware = createLogger();
const middleware = [
  thunkMiddleware.withExtraArgument(api),
  reduxPackMiddleware,
  DEBUG && loggerMiddleware,
].filter(Boolean);

export const store = createStore(
    rootReducer,
    applyMiddleware(
        ...middleware
    )
);
