/**
activatedModules: to define the activated modules
*/
const activatedModules = [];
if (process.env.REACT_APP_ACTIVATE_PRONOSTICS_MODULE)
  activatedModules.push('PRONOSTICS');

if (process.env.REACT_APP_ACTIVATE_FANWALL_MODULE)
  activatedModules.push('FANWALL');

if (process.env.REACT_APP_ACTIVATE_DISTRICT_CRUD === 'true')
  activatedModules.push('DISTRICT');

if (process.env.REACT_APP_ACTIVATE_PAYMENT_MODULE === 'true')
  activatedModules.push('PAYMENT')

if(process.env.REACT_APP_ACTIVATE_TICKET_MODULE === 'true')
  activatedModules.push('TICKET')

if(process.env.REACT_APP_ACTIVATE_YANGO_TRACKING === 'true')
  activatedModules.push('YANGO')

if(process.env.REACT_APP_ACTIVATE_POS === 'true')
    activatedModules.push('POS')

if(process.env.REACT_APP_ACTIVATE_SOMATCH_MODULE === 'true')
        activatedModules.push('SOMATCH')

export { activatedModules }
