//Core react libs
import React from 'react';
import { connect } from 'react-redux';

// Translation
import {getTranslate, getLanguages, getActiveLanguage } from 'react-localize-redux';

//UI components
import { Row, Col, Button } from 'reactstrap';

// Local custom components/libs
import { layoutActions } from '../../../../../../_services/actions';
import { isBlockAccessDenied } from '../../../../../../_helpers';
import {
   InputSelectWithLabel,
   ActionUpdateButton
 } from '../../../../../../_components/form'

import {
  AssignedToBlock
} from './AssignedToBlock'



/** Ticket additional Information block **/
 function AdditionalInformationAsideBlockComponent(props) {
   const {
     translate,
     additional_info_block_data,
     alert,
     show_section_aside_bar,
     dispatch
   } = props;

   //To hide the section aside bar
   const hideSectionPreview = function(){
     dispatch(layoutActions.hideSectionAsideBar());
   }

   /**
   * getFieldErrorMessages
   * To get the error messages for each field
   * @param [field]
   * @param  {Object}[errorBag] - error messages
   */
   const getFieldErrorMessages = function(field){
     let httpErrorsExist = false;
     if(alert && alert.errors){
       let httpFieldErrors = this.props.alert.errors;
       if(httpFieldErrors && httpFieldErrors[field] && httpFieldErrors[field].length > 0){
         httpErrorsExist = true;
         return httpFieldErrors[field];
       }
       else
         httpErrorsExist = false;
     }
     if(!httpErrorsExist)
       return additional_info_block_data.errorBag.field_validation_messages;
   }

   if(additional_info_block_data){
     const {
       ticket,
       attributes,
       saved,
       loading,
       errorBag,
       handleChange,
       handleSubmit
     } = additional_info_block_data;
     return (
       <aside className="aside-section-menu">
           <div className="tab-content">
             {/** Heading **/}
             <Row className="pl-3 pr-3">
               <Col xs="10">
                 <h5 className="mt-3">{translate('Additional information')}</h5>
               </Col>
               <Col xs="2">
               {show_section_aside_bar &&
               <Button
               block={true}
               color="link"
               className="card-header-action btn btn-minimize p-3 text-right"
               onClick={hideSectionPreview}
               >
                <i className="fal fa-times"></i>
               </Button>
               }
               </Col>
             </Row>
             {/** Content **/ }
             <div className="mt-4 pl-3 pr-3">
              <Row>
                 <Col xs="12">
                   <InputSelectWithLabel
                     name = "topic_id"
                     id = "topic_id"
                     options={attributes.filter(attribute=>attribute.type === 'topic')}
                     value = {ticket.topic_id}
                     label={translate("Topic")}
                     onChange = {handleChange}
                     errorMessages = {getFieldErrorMessages('topic_id')}
                     optionLabelFields={['label']}
                     required={true}
                   />
                 </Col>
                 <Col xs="12">
                   <InputSelectWithLabel
                     name = "type_id"
                     id = "type_id"
                     options={attributes.filter(attribute=>attribute.type === 'type')}
                     value = {ticket.type_id}
                     label={translate("Type")}
                     onChange = {handleChange}
                     errorMessages = {getFieldErrorMessages('type_id')}
                     optionLabelFields={['label']}
                     required={true}
                   />
                 </Col>
                 <Col xs="12">
                   <InputSelectWithLabel
                     name = "priority_id"
                     id = "priority_id"
                     options={attributes.filter(attribute=>attribute.type === 'priority')}
                     value = {ticket.priority_id}
                     label={translate("Priority")}
                     onChange = {handleChange}
                     errorMessages = {getFieldErrorMessages('priority_id')}
                     optionLabelFields={['label']}
                     required={true}
                   />
                 </Col>
                 {(!isBlockAccessDenied('TICKET_ASSIGNED_TO')) &&
                   <Col xs="12">
                    <div>
                       <AssignedToBlock
                          ticket={ticket}
                          errorBag={errorBag}
                          handleChange={handleChange}
                       />
                     </div>
                   </Col>
                 }

               </Row>
             </div>
             {/** Content Ends **/}

           </div>

           {show_section_aside_bar &&
           <div className="preview-footer flex-column pl-3 pr-3">
             <ActionUpdateButton
                 block={true}
                 size="md"
                 onClick={handleSubmit}
                 saved={saved}
                 label={(saved)?translate("Saved"):translate("Save")}
                 disabled={loading || (alert != null) || errorBag.field_validation_messages.length > 0 }
                 loading={loading}
               />
           </div>
           }
         </aside>
     );
   }
   else{
     return null;
   }
 }


 function mapStateToProps(state) {
     return {
         translate: getTranslate(state.localize),
         languages: getLanguages(state.localize),
         activeLanguage: getActiveLanguage(state.localize).code,
         alert: state.http_request.response,
         additional_info_block_data:state.layout.additional_info_block_data,
         show_section_aside_bar:state.layout.show_section_aside_bar
     };
 }
 let AdditionalInformationAsideBlock = connect(mapStateToProps)(AdditionalInformationAsideBlockComponent);
 export { AdditionalInformationAsideBlock };
