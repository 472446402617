/**
settingsActions
To dispatch the settings actions to handle settings actions
*/


export const settingsActions = {
    showSlug,
    hideSlug,
};

/**
* showSlug
* To show the Slug in all forms
*/
function showSlug() {
  return { type: 'SHOW_SLUG'}
}

/**
* hideSlug
* To hide the Slug in all forms
*/
function hideSlug() {
 return { type: 'HIDE_SLUG'}
}
