/**
authActions
To dispatch the authentication actions
@functions:
login,
logout,
forgotPwd,
resetPwd,
sendActivationLink,
activateAccount
*/

import { urlConfig } from '../../../_config';
import { history, store, getLastUrl, onHttpFailure } from '../../../_helpers';
import { sessionActions, httpActions } from '../../../_services/actions';
import { getActiveLanguage } from 'react-localize-redux';


export const authActions = {
  initLoginRequest,
  login,
  socialAttemptLogin,
  createLoginSessionFromToken,
  forgotPwd,
  resetPwd,
  sendActivationLink,
  activateAccount,
  editProfile,
};

function initLoginRequest() {
  return { type: 'AUTH_AUTHENTICATION_INIT' };
}

/**
* login
* To dispatch the login request
* @param ['email', 'password']
* @param  {Object} reducer [reducer with action type based on success or error response from the API]
*/
function login(email, password) {
  const state = store.getState();
  const locale = getActiveLanguage(state.localize).code;
  return (dispatch, _, api) => {
    dispatch(init());
    dispatch(
      {
        type: 'HTTP_REQUEST',
        promise: api
          .post(urlConfig.API_URL + 'oauth/login', {
            email,
            password
          }),
        meta: {
          onSuccess: (response, getState) => {
            dispatch(sessionActions.setSession(response.data));
            let access_token = response.data.access_token;
            api.get(urlConfig.API_URL + 'account/user')
              .then((response) => {
                const user = response.data;
                localStorage.setItem('user', JSON.stringify(user));
                dispatch(success(user));

                //Cube js authentication
                let data = {
                  'token': access_token
                };
                api.post(urlConfig.CUBE_API_URL + '/authenticate', data).then(
                  response => {
                    dispatch(successCubeJSAuthentication(response.data));
                  },
                  error => {
                    api.post(urlConfig.CUBE_API_URL + '/authenticate', data).then(
                      response => {
                        dispatch(successCubeJSAuthentication(response.data));
                      },
                      error => {
                        dispatch(failureCubeJSAuthentication(error))
                      }
                    );
                  }
                );


                const urlToRedirect = getLastUrl();

                // To reset the http action state
                dispatch(httpActions.initHTTP());
                if (urlToRedirect)
                  history.push(urlConfig.BASE_URL + urlToRedirect);
                else
                  history.push(urlConfig.BASE_URL + '/' + locale);
              })
              .catch((error) => {
                dispatch(failure(error));
              });
          },
          onFailure: (response, getState) => {
            if (response.json)
              dispatch(failure(response));
            else {
              // For handling 500 errors
              let errorResponse = {
                'json': {
                  'message': String(response),
                  'description': String(response),
                  'type': 'error',
                  'status': '500'
                }
              }
              dispatch(failure(errorResponse));
            }
          }
        }
      }
    );
  };
  function init() { return { type: 'AUTH_LOGIN_START' } }
  function success(payload) { return { type: 'AUTH_LOGIN_SUCCESS', payload } }
  function failure(payload) { return { type: 'AUTH_LOGIN_FAILURE', payload } }

  function successCubeJSAuthentication(payload) { return { type: 'CUBEJS_AUTH_LOGIN_SUCCESS', payload } }
  function failureCubeJSAuthentication(payload) { return { type: 'CUBEJS_AUTH_LOGIN_FAILURE', payload } }
}

/**
* SocialAttemptLogin
* To dispatch the login request
* @param ['email', 'password']
* @param  {Object} reducer [reducer with action type based on success or error response from the API]
*/
function socialAttemptLogin(social_token, social_provider, failure) {
  const state = store.getState();
  const locale = getActiveLanguage(state.localize).code;
  return (dispatch, _, api) => {
    dispatch(init());
    dispatch(
      {
        type: 'HTTP_REQUEST',
        promise: api
          .post(urlConfig.API_URL + 'oauth/social/attempt_login', {
            social_token,
            social_provider
          }),
        meta: {
          onSuccess: (response, getState) => {
            dispatch(sessionActions.setSession(response.data));
            api.get(urlConfig.API_URL + 'account/user')
              .then((response) => {
                const user = response.data;
                localStorage.setItem('user', JSON.stringify(user));
                dispatch(success(user));
                const urlToRedirect = getLastUrl();

                // To reset the http action state
                dispatch(httpActions.initHTTP());
                if (urlToRedirect)
                  history.push(urlConfig.BASE_URL + urlToRedirect);
                else
                  history.push(urlConfig.BASE_URL + '/' + locale);
              })
              .catch((error) => {
                dispatch(failure(error));
              });
          },
          onFailure: (response, getState) => {
            if (response.json)
              dispatch(failure(response));
            else {
              // For handling 500 errors
              let errorResponse = {
                'json': {
                  'message': String(response),
                  'description': String(response),
                  'type': 'error',
                  'status': '500'
                }
              }
              dispatch(failure(errorResponse));
            }
          }
        }
      }
    );
  };
  function init() { return { type: 'AUTH_LOGIN_START' } }
  function success(payload) { return { type: 'AUTH_LOGIN_SUCCESS', payload } }
  //  function failure(payload) { return { type: 'AUTH_LOGIN_FAILURE', payload } }
}

/**
* createLoginSessionFromToken
* To dispatch the createLoginSessionFromToken
* @param ['email', 'password']
* @param  {Object} reducer [reducer with action type based on success or error response from the API]
*/
function createLoginSessionFromToken(refresh_token) {
  const state = store.getState();
  const locale = getActiveLanguage(state.localize).code;
  return (dispatch, _, api) => {
    localStorage.setItem('refresh_token', refresh_token);
    dispatch(init());
    api.post(urlConfig.API_URL + 'oauth/token/refresh',
      { refresh_token })
      .then(
        data => {
          //  dispatch(tokenRefreshSuccess());
          dispatch(sessionActions.setSession(data.data));

          api.get(urlConfig.API_URL + 'account/user')
            .then((response) => {
              const user = response.data;
              localStorage.setItem('user', JSON.stringify(user));
              dispatch(success(user));
              const urlToRedirect = getLastUrl();

              // To reset the http action state
              dispatch(httpActions.initHTTP());
              if (urlToRedirect)
                history.push(urlConfig.BASE_URL + urlToRedirect);
              else
                history.push(urlConfig.BASE_URL + '/' + locale);
            })
            .catch((error) => {
              dispatch(failure(error));
            });

        },
        error => {
          console.log('eror token refresh');
          dispatch(failure(error));
        }
      );
  };
  function init() { return { type: 'AUTH_LOGIN_START' } }
  function success(payload) { return { type: 'AUTH_LOGIN_SUCCESS', payload } }
  function failure(payload) { return { type: 'AUTH_LOGIN_FAILURE', payload } }
}



/**
* forgotPwd
* To dispatch the forgot password request
* @param ['email']
* @param  {Object} reducer [reducer with action type based on success or error response from the API]
*/

function forgotPwd(email) {
  return (dispatch, _, api) => {
    dispatch(
      {
        type: 'HTTP_REQUEST',
        promise: api
          .post(urlConfig.API_URL + 'account/password/send_reset_email', {
            email
          })
      }
    );
  };
}

/**
* resetPwd
* To dispatch the forgot password request
* @param {Object} ['email','password','password_confirmation','token']
* @return  {Object} reducer [reducer with action type based on success or error response from the API]
*/
function resetPwd(email, password, password_confirmation, token) {
  return (dispatch, _, api) => {
    dispatch(
      {
        type: 'HTTP_REQUEST',
        promise: api
          .post(urlConfig.API_URL + 'account/password/reset', {
            email, password, password_confirmation, token
          })
      }
    );
  };
}

/**
* sendActivationLink
* To dispatch the request to send activation link in email
* @return  {Object} reducer [reducer with action type based on success or error response from the API]
*/
function sendActivationLink(onHttpSuccess, onHttpFailure) {
  return (dispatch, _, api) => {
    dispatch(
      {
        type: 'HTTP_REQUEST',
        promise: api
          .get(urlConfig.API_URL + 'account/send_activation_email'),
        meta: {
          onFailure: (response, getState) => {
            onHttpFailure(response, dispatch);
          },
          onSuccess: (response, getState) => {
            onHttpSuccess(response);
          }
        }
      }
    );
  };
}

/**
* activateAccount
* To dispatch the request to activate the account
* @return  {Object} reducer [reducer with action type based on success or error response from the API]
*/

function activateAccount(email_token) {
  return (dispatch, _, api) => {
    dispatch(
      {
        type: 'HTTP_REQUEST',
        promise: api.post(urlConfig.API_URL + 'account/activate', { email_token }),
        meta: {
          onSuccess: (response, getState) => {
            let user = JSON.parse(localStorage.getItem('user'));
            user.activated = 1;
            localStorage.setItem('user', JSON.stringify(user));
            dispatch(activationSuccess());
          }
        }
      }
    );
  };

  function activationSuccess() { return { type: 'AUTH_ACTIVATED' } }
}


/**
* EditProfile
* To dispatch the request to edit the user profile
* @param  {Object} reducer [reducer with action type based on success or error response from the API]
*/

function editProfile(id, data, onHttpSuccess, onFailure) {
  data._method = "PUT";
  return (dispatch, _, api) => {
    dispatch(
      {
        type: 'HTTP_REQUEST',
        promise: api.postFormData(urlConfig.API_URL + 'account/users/' + id, data),
        meta: {
          onSuccess: (response, getState) => {
            let user = response.data;
            api.get(urlConfig.API_URL + 'account/roles/' + user.role_id)
              .then((response) => {
                const role = response.data;
                user.role = role;
                localStorage.setItem('user', JSON.stringify(user));
                dispatch(editedProfile(user));
                onHttpSuccess(response);
              })
              .catch((error) => {
                onHttpFailure(error, dispatch, onFailure);
              });
          }
        }
      }
    );
  };


  function editedProfile(payload) { return { type: 'AUTH_USER_UPDATED', payload } }
}
