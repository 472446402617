// Core react libs
import React, { Component, Suspense } from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'recompose';

// Translation
import { getTranslate, getLanguages, getActiveLanguage } from 'react-localize-redux';

//UI components
import { Container,  Col, Row, CardGroup} from 'reactstrap';
import {
  AppFooter,
  AppHeader
} from '@coreui/react';

// Custom components or lib functions
import { setLastUrl } from '../../_helpers';
import { translateActions } from '../../_services/actions';
const FullPageHeader = React.lazy(() => import('./FullPageHeader'));
const DefaultFooter = React.lazy(() => import( '../../_components/layout/DefaultFooter'));

class FullPageLayout extends Component {
  loading = () => <div className="animated fadeIn pt-1 text-center"><div className="sk-spinner sk-spinner-pulse"></div></div>;
  constructor(props) {
      super(props);
      //To set the translation for general alert or validation messages

      this.props.dispatch(
        translateActions.addTranslationForPage(
        this.props.languages,
        'Messages',
        'Alert'
        )
      );
      const pages = ['Auth'];
      this.props.dispatch(
        translateActions.addTranslationForValidation(
        this.props.languages,
        'Messages',
        'Validation',
        pages
        )
      );
  }
  componentWillMount() {
    let app_element = document.getElementById('root');
    app_element.classList.remove('with-message-bar');
    setLastUrl(this.props.history.location.pathname, this.props);
    this.unlisten = this.props.history.listen((location, action) => {
      setLastUrl(location.pathname, this.props);
    });
  }

  componentWillUnmount() {
      this.unlisten();
  }

  render() {
    const {activeLanguage, component: Component, ...rest} = this.props;
    return (
      <Route {...rest} render={props => (
        <div className="app">
          <AppHeader className="border-bottom-dotted navbar-lg" fixed>
            <Suspense fallback={this.loading()}>
              <FullPageHeader {...this.props}  />
            </Suspense>
          </AppHeader>
          <div className="app-body">
              <Container className="fullpage-container">
                  <Row className="justify-content-center">
                    <Col md="12">
                      <CardGroup>
                        <Suspense fallback={this.loading()}>
                          <Component {...props} />
                        </Suspense>
                      </CardGroup>
                    </Col>
                  </Row>
              </Container>
          </div>
          <AppFooter className="fullpage-footer">
            <Suspense fallback={this.loading()}>
              <DefaultFooter />
            </Suspense>
          </AppFooter>
        </div>
      )} />

    );
  }
}


const composeRouter = compose(
  withRouter
)
const mapStateToProps = state => (
  {
    activeLanguage: getActiveLanguage(state.localize).code,
    translate: getTranslate(state.localize),
    languages: getLanguages(state.localize),
    user: state.authentication.user,
  }
);
FullPageLayout = composeRouter(FullPageLayout)
FullPageLayout = connect(mapStateToProps)(FullPageLayout);
export default FullPageLayout;
