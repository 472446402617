//Core react libs
import React, {Component} from 'react';
import { connect } from 'react-redux';

// Translation
import {getTranslate, getLanguages, getActiveLanguage } from 'react-localize-redux';


// Local custom components/libs
import {
   FieldsWithTab
} from './FieldsWithTab'
import {
   FieldsWithOutTab
} from './FieldsWithOutTab'


/** Translation tab container card **/
class TranslationFieldsContainer extends Component {

  /**
  * To render the component
  * @returns  {Object} HTML element
  */
  render() {
    const { languages, data, handleChange, fields, errorBag } = this.props;
    return (
          <div>
            {languages.length > 1 &&
              <FieldsWithTab
               data={data}
               handleChange={handleChange}
               fields={fields}
               errorBag={errorBag}
               />
            }
            {languages.length <= 1 &&
              <FieldsWithOutTab
               data={data}
               handleChange={handleChange}
               fields={fields}
               errorBag={errorBag}
               />
            }
          </div>
    );
  }
}



function mapStateToProps(state) {
    return {
        translate: getTranslate(state.localize),
        languages: getLanguages(state.localize),
        activeLanguage: getActiveLanguage(state.localize).code,
        loading: state.http_request.sending,
        alert: state.http_request.response,
    };
}

TranslationFieldsContainer = connect(mapStateToProps)(TranslationFieldsContainer);
export {TranslationFieldsContainer};
