/**
layout
The reducer function to define the layout state such as breadcrumbs etc.,
*/
const initialState = { breadcrumbs:[], page_loader:false, show_section_aside_bar:false, section_aside_bar_data:null, additional_info_block_data: null, show_settings_icon:true, show_settings_aside_bar:false };
export function layout(state = initialState, action) {
  switch (action.type) {
    case 'SET_BREADCRUMB':
      state.breadcrumbs = action.payload;
      return state;
    case 'SHOW_PAGE_LOADER':
      state.page_loader = true;
      return state;
    case 'HIDE_PAGE_LOADER':
      state.page_loader = false;
      return state;
    case 'SHOW_ASIDEBAR':
      state.show_section_aside_bar = true;
      return state;
    case 'HIDE_ASIDEBAR':
      state.show_section_aside_bar = false;
      return state;
    case 'SET_SECTION_ASIDEBLOCK_DATA':
      state.section_aside_bar_data = action.payload;
      return state;
    case 'SET_ADDITIONAL_INFO_BLOCK_DATA':
      state.additional_info_block_data = action.payload;
      return state;
    case 'SHOW_SETTING_ICON':
      state.show_settings_icon = true;
      return state;
    case 'HIDE_SETTING_ICON':
      state.show_settings_icon = false;
      return state;
    case 'SHOW_SETTING_ASIDEBAR':
      state.show_settings_aside_bar = true;
      return state;
    case 'HIDE_SETTING_ASIDEBAR':
      state.show_settings_aside_bar = false;
      return state;
    default:
      return state
  }
}
