/**
InputTextFieldWithLabel
Input text field Component with field error message
@functions:InputTextFieldWithLabelComponent
*/
// Core react dependencies
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/** UI components **/
import { Col, Input, FormGroup, Label, InputGroup, InputGroupText, InputGroupAddon } from 'reactstrap';

// Custom modules/components
import { FormFieldErrorText } from './FormFieldErrorText';

InputTextFieldWithLabelComponent.propTypes = {
    type: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number
    ]),
    onChange: PropTypes.func,
    required:PropTypes.bool,
    errorMessages: PropTypes.array,
    inline: PropTypes.bool,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.node,
    showLabel: PropTypes.bool
}

InputTextFieldWithLabelComponent.defaultProps = {
  type: 'text',
  required: false,
  errorMessages: [],
  inline: false,
  disabled: false,
  className:'',
  showLabel: true
}

function InputTextFieldWithLabelComponent(props) {
  const {
    type,
    name,
    id,
    label,
    placeholder,
    value,
    onChange,
    required,
    errorMessages,
    inline,
    disabled,
    locked,
    rows,
    cols,
    className,
    children,
    showLabel
  } = props;
  return (
    <div  className={classNames(className, {"mb-3":className === ''})}>
      {inline &&
        <FormGroup row>
          <Col md="3">
            <Label htmlFor={name}>
              {label}
              {(required && label !== '') &&
                <span className="text-danger">*</span>
              }
            </Label>
          </Col>
          <Col xs="12" md="9">
          <InputGroup>
            {locked &&
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="fas fa-lock"></i>
              </InputGroupText>
            </InputGroupAddon>
            }
            <Input type={type} id={id} cols={cols} rows={rows} disabled={disabled || locked} name={name} placeholder={placeholder} value={value} onChange={onChange} />
          </InputGroup>
            {errorMessages && errorMessages.length > 0 &&
              <FormFieldErrorText messages={errorMessages} field={id}  />
            }
            <div className="text-muted">{children}</div>
          </Col>
        </FormGroup>
      }
      {!inline &&
        <FormGroup>
          {showLabel &&
          <Label htmlFor={name}>
              {label}
              {(required && label !== '') &&
                <span className="text-danger">*</span>
              }
          </Label>
          }
          <InputGroup>
            {locked &&
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="fas fa-lock"></i>
              </InputGroupText>
            </InputGroupAddon>
            }
            <Input type={type} name={name} id={id} cols={cols} rows={rows} disabled={disabled} placeholder={placeholder} value={value} onChange={onChange} />
          </InputGroup>
          {errorMessages && errorMessages.length > 0 &&
            <FormFieldErrorText messages={errorMessages} field={id}  />
          }
          <div className="text-muted">{children}</div>
        </FormGroup>
      }
    </div>
  );
}

export {InputTextFieldWithLabelComponent as InputTextFieldWithLabel};
