//Core react libs
import React, {Component} from 'react';
import { connect } from 'react-redux';

// Translation
import {getTranslate, getLanguages, getActiveLanguage } from 'react-localize-redux';

//UI components
import {
        Row, Col,
        Button
      } from 'reactstrap';


import { layoutActions, settingsActions } from '../../_services/actions';

// Local custom components/libs
import {
InputSwitchWithLabel
 } from '../../_components/form'

/** Sections preview block **/
class SettingsSideBar extends Component {


  /**
  * To hide settings right bar
  */
  hideSettingsAsideBar = () =>{
    this.props.dispatch(layoutActions.hideSettingsAsideBar());
  }

  /**
  * when show slug checkbox toggled
  * @param {Object} event
  */
  handleToggleSlug = (event) => {
      const { checked } = event.target;
      let value = 0;
      if(checked)
        value = 1;
      if(value)
      this.props.dispatch(settingsActions.showSlug());
      else
      this.props.dispatch(settingsActions.hideSlug());
  }

  /**
  * To render the component
  * @returns  {Object} HTML element
  */
  render() {
    const {
      settings,
      translate
    } = this.props;

    return (
      <aside className="aside-settings-menu">
          <Row className="pl-3 pr-3">
            <Col xs="9">
              <h5 className="my-3">{translate('Settings')}</h5>
            </Col>
            <Col xs="3">
              <Button
              block={true}
              color="link"
              className="card-header-action btn btn-minimize p-3 text-right"
              onClick={this.hideSettingsAsideBar}
              >
               <i className="fal fa-times"></i>
              </Button>
            </Col>
          </Row>
          <Row className="pl-3 pr-3 mt-3">
           <Col xs="12">
             <InputSwitchWithLabel
               name="show_slug"
               id="show_slug"
               color={'success'}
               checked={settings.show_slug?true:false}
               onChange={this.handleToggleSlug}
               label={translate('Show slug')}
               inline={true}
               labelcolspace="8"
               switchcolspace="4"
              />
           </Col>
          </Row>
      </aside>
    );
  }
}



function mapStateToProps(state) {
    return {
        translate: getTranslate(state.localize),
        languages: getLanguages(state.localize),
        activeLanguage: getActiveLanguage(state.localize).code,
        loading: state.http_request.sending,
        alert: state.http_request.response,
        show_section_aside_bar:state.layout.show_section_aside_bar,
        settings: state.settings,
    };
}

SettingsSideBar = connect(mapStateToProps)(SettingsSideBar);
export {SettingsSideBar};
