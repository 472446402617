export default class HttpResponseError {
	constructor(response, errorJson) {
		this.response = response;
		this.json = errorJson;
	}

	get status() {
		return this.response.status;
	}

	get statusText() {
		return this.response.statusText;
	}
}
