import {
  AppSidebarNav,
} from '@coreui/react';
import { urlConfig } from '../../_config';

var _reactRouterDom = require('react-router-dom');
var _reactstrap = require('reactstrap');
var _react = require('react');
var _react2 = _interopRequireDefault(_react);
var _classnames = require('classnames');
var _classnames2 = _interopRequireDefault(_classnames);
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/** To extend the core react sidebar to remove active class for Dashboard **/
class SideBar extends AppSidebarNav {


    navDropdown = function navDropdown(item, key) {
      var classIcon = (0, _classnames2.default)('nav-icon', item.icon);
      return _react2.default.createElement(
        'li',
        { key: key, className: item.class+' '+this.activeRoute(item.url, this.props) },
        _react2.default.createElement(
          'a',
          { className: 'nav-link nav-dropdown-toggle', href: '#', onClick: this.handleClick },
          _react2.default.createElement('i', { className: classIcon }),
          item.name,
          this.navBadge(item.badge)
        ),
        _react2.default.createElement(
          'ul',
          { className: 'nav-dropdown-items' },
          this.navList(item.children)
        )
      );
    };

    navLink = function navLink(item, key, classes) {
      // To check if current page is not home page and not to make that link active
      let isCurrentPageHomePage =(this.props.location.pathname === (`${urlConfig.BASE_URL}/${this.props.activeLanguage}` || `${urlConfig.BASE_URL}/${this.props.activeLanguage}/`))
      var url = item.url ? item.url : '';
      return _react2.default.createElement(
        _reactstrap.NavItem,
        { key: key, className: classes.item },
        this.isExternal(url) ? _react2.default.createElement(
          _reactstrap.NavLink,
          { href: url, className: classes.link, active: false },
          _react2.default.createElement('i', { className: classes.icon }),
          item.name,
          this.navBadge(item.badge)
        ) : _react2.default.createElement(
          _reactRouterDom.NavLink,
          { to: url, className: classes.link, activeClassName:(!isCurrentPageHomePage && item.isHomePage)?'': 'active', onClick: this.hideMobile },
          _react2.default.createElement('i', { className: classes.icon }),
          item.name,
          this.navBadge(item.badge)
        )
      );
    };

}
export default SideBar;
