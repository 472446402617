/**
roleActions
To dispatch the account actions
@functions:
getRoles,
*/

import { urlConfig } from '../../../_config';

import { onHttpFailure } from '../../../_helpers';
import HttpRequest from '../../../_services/http/request';
const api = new HttpRequest();
export const roleActions = {
    getRoleList,
    getRoleListByGroupId,
    getRole,
    updateRole,
    deleteRole,
    createRole
};

/**
* getRoleList
* To dispatch the get user list request
* @param  {Object} reducer [reducer with action type based on success or error response from the API]
*/

function getRoleList(dispatch, onSuccess, onFailure){
  api.get(urlConfig.API_URL+'account/roles').then(
      data => {
        onSuccess(data);
      },
      error => {
        onHttpFailure(error, dispatch, onFailure);
      }
  );
}

/**
* getRoleList
* To dispatch the get user list request
* @param  {Object} reducer [reducer with action type based on success or error response from the API]
*/

function getRoleListByGroupId(group_id, dispatch, onSuccess, onFailure){
  api.get(urlConfig.API_URL+'account/roles?search='+group_id+'&searchFields=group_id').then(
      data => {
        onSuccess(data);
      },
      error => {
        onHttpFailure(error, dispatch, onFailure);
      }
  );
}

/**
* getRole detail
* To dispatch the getRole request
* @param  {Object} reducer [reducer with action type based on success or error response from the API]
*/

function getRole(id, dispatch, onSuccess, onFailure){
  api.get(urlConfig.API_URL+'account/roles/'+id).then(
      data => {
        onSuccess(data);
      },
      error => {
        onHttpFailure(error, dispatch, onFailure);
      }
  );
}
/**
* createRole detail
* To dispatch the create role request
* @param  {Object} reducer [reducer with action type based on success or error response from the API]
*/

function createRole(data, onHttpSuccess){
  return (dispatch, _, api) => {
    dispatch(
      {
        type: 'HTTP_REQUEST',
        promise: api
          .post(urlConfig.API_URL+'account/roles/', data),
        meta: {
            onFailure: (response, getState) => {
              onHttpFailure(response,dispatch);
            },
            onSuccess: (response, getState) => {
              onHttpSuccess(response);
            }
        }
      }
    );
  };
}

/**
* updateRole detail
* To dispatch the updateRole request
* @param  {Object} reducer [reducer with action type based on success or error response from the API]
*/

function updateRole(id, data, onHttpSuccess){
  return (dispatch, _, api) => {
    dispatch(
      {
        type: 'HTTP_REQUEST',
        promise: api
          .put(urlConfig.API_URL+'account/roles/'+id, data),
        meta: {
            onFailure: (response, getState) => {
              onHttpFailure(response,dispatch);
            },
            onSuccess: (response, getState) => {
              onHttpSuccess(response);
            }
        }
      }
    );
  };
}


/**
* deleteRole
* To dispatch the deleteRole request
* @param  {Object} reducer [reducer with action type based on success or error response from the API]
*/
function deleteRole(role_id, onHttpSuccess){
  return (dispatch, _, api) => {
    dispatch(
      {
        type: 'HTTP_REQUEST',
        promise: api
          .delete(urlConfig.API_URL+'account/roles/'+role_id),
        meta: {
            onFailure: (response, getState) => {
              onHttpFailure(response,dispatch);
            },
            onSuccess: (response, getState) => {
              onHttpSuccess(role_id, response);
            }
        }
      }
    );
  };
}
