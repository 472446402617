/**
ActionUpdateButton
Component button with loader
@functions:ActionUpdateButton
*/

// Core react dependencies
import React from 'react';
import PropTypes from 'prop-types';

//UI components
import {
  Button
} from 'reactstrap';

ActionUpdateButtonComponent.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    saved: PropTypes.bool,
    onClick: PropTypes.func,
    size: PropTypes.string,
    block: PropTypes.bool,
    color: PropTypes.string,
    outline:PropTypes.bool,
    children: PropTypes.object
}

ActionUpdateButtonComponent.defaultProps = {
  disabled: false,
  loading: false,
  saved:false,
  size: 'lg',
  block: true,
  outline: false,
  color: 'primary'
}

function ActionUpdateButtonComponent(props) {
  const {className, label, disabled, loading, saved, onClick, size, block, color, outline} = props;
  const withLoaderLabel =<span> <i className="fa fa-spinner fa-spin"></i>&nbsp;{label}</span>;
  const withoutLoaderLabelNotSaved  = <span><i className="fal fa-save"></i>&nbsp;{label}</span>
  const withoutLoaderLabelSaved  = <span><i className="fa fa-check-circle"></i>&nbsp;{label}</span>
  const buttonContent = (loading)?withLoaderLabel:((saved)?withoutLoaderLabelSaved:withoutLoaderLabelNotSaved);
  return(
      <Button type="submit"
         color={(saved)? 'success': color}
         block={(block !== undefined)?block:true}
         outline={outline}
         size={size}
         disabled={disabled || loading}
         onClick={onClick}
         className={className}
         >
        {buttonContent}
      </Button>
  );
}


export {ActionUpdateButtonComponent as ActionUpdateButton};
