/**
Social login
*/

export const FACEBOOK_CONFIG = {
    APP_ID:process.env.REACT_APP_FB_APPID,
    APP_VERSION:"v3.3"
};

export const APPLE_CONFIG = {
    CLIENT_ID:process.env.REACT_APP_APPLE_CLIENTID,
    REDIRECT_URI:process.env.REACT_APP_APPLE_REDIRECT_URI
};
