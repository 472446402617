/**
translateActions
To dispatch the translation actions
@functions:
*/

import { addTranslationForLanguage, setActiveLanguage} from 'react-localize-redux';

import { urlConfig, defaultLocale, fallbackLocale } from '../../_config';
import { history } from '../../_helpers';
import HttpRequest from '../../_services/http/request';

export const translateActions = {
    addTranslationForPage,
    addTranslationForValidation,
    addTranslationForComponent,
    setActiveLanguageForPage,
    getLocales
};



/**
* setTranslationForPage
* To setup the translation for a page
* @param ['languages', 'moduleName', 'blockName']
* @param  {Object} reducer [reducer with state.localize]
*/
function addTranslationForPage(languages, moduleName, blockName) {
    return dispatch => {
        languages.map((language) => {
          let translations =  null;
          try {
            translations = require(`../../_translations/${language.code}/pages/${moduleName}.json`);
          } catch (err) {
            let fallback_locale = fallbackLocale;
            translations = require(`../../_translations/${fallback_locale}/pages/${moduleName}.json`);
          }
          dispatch(addTranslationForLanguage(translations[blockName],language.code));
          return true;
        });
    };
}

/**
* set Translation for validation messages
* To setup the translation for a page
* @param ['languages', 'moduleName', 'blockName', '']
* @param  {Object} reducer [reducer with state.localize]
*/
function addTranslationForValidation(languages, moduleName, blockName, pages) {
    return dispatch => {
        languages.map((language) => {
          let translations = null;
          try{
            translations = require(`../../_translations/${language.code}/pages/${moduleName}.json`);
          }
          catch (err) {
            let fallback_locale = fallbackLocale;
            translations = require(`../../_translations/${fallback_locale}/pages/${moduleName}.json`);
          }

          let validationMessages = translations[blockName];
          pages.map(page=>{
            dispatch(addTranslationForLanguage(validationMessages[page],language.code));
            return true;
          })
          return true;
        });
    };
}


/**
* setTranslationForComponent
* To setup the translation for a component
* @param {array} languages
* @param {string} componentName
* @return  {Object} reducer [reducer with state.localize]
*/
function addTranslationForComponent(languages, componentName) {
    return dispatch => {
        languages.map((language) => {
          let translations = null;
          try{
           translations = require(`../../_translations/${language.code}/components/${componentName}.json`);
          }
          catch (err) {
            let fallback_locale = fallbackLocale;
            translations = require(`../../_translations/${fallback_locale}/components/${componentName}.json`);
          }
          dispatch(addTranslationForLanguage(translations,language.code));
          return true;
        });
    };
}
/**
* setActiveLanguage
* To set the active language for the page
* @param [ 'activeLanguage']
* @param  {Object} reducer [reducer with state.localize]
*/
function setActiveLanguageForPage(languages, activeLanguage) {
    return dispatch => {
        let activeLanguageExists = false;
        languages.map((language) => {
          if(activeLanguage !== undefined && (language.code === activeLanguage)){
            activeLanguageExists = true;
          }
          return true;
        });
        if(activeLanguage){
          if(activeLanguageExists){
            dispatch(setActiveLanguage(activeLanguage));
          }
          else{ //to set the default locale in the url if any invalid lang code found
            dispatch(setActiveLanguage(defaultLocale));
            let currentLocation = history.location;
            let currentURL = currentLocation.pathname;
            var newURL = currentURL.replace(`/${activeLanguage}`, `/${defaultLocale}`);
            currentLocation.pathname = newURL;
            history.replace(currentLocation);
          }
        }
        else{ // to set the default locale in the url if no lang code exists
          activeLanguage = defaultLocale;
          dispatch(setActiveLanguage(defaultLocale));
          let currentLocation = history.location;
          let currentURL = currentLocation.pathname;
          let langFound = currentURL.search(`/${activeLanguage}`);
          if(langFound === -1){ //to set the default locale in the url if language code is not set
            history.replace(`${urlConfig.BASE_URL}/${activeLanguage}${currentURL}`);
          }
        }
    };
}

/**
* getLocales
* To fetch the locales from server
* @param ['dispatch', 'onSuccess', 'onFailure']
* @param  {Object} callback functions
*/
function getLocales(dispatch, onSuccess, onFailure){
  const api = new HttpRequest();
  api.get(urlConfig.API_URL+'system/locales').then(
      data => {
        onSuccess(data);
      },
      error => {
        onFailure(error);
      }
  );
}
