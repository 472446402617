/**
ModalSuccessMessageComponent
Success message to show when form is submitted
*/

// Core react dependencies
import React from 'react';
import PropTypes from 'prop-types';

//UI components
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

ModalSuccessMessageComponent.propTypes = {
    className: PropTypes.string,
    openModal: PropTypes.bool,
    translate: PropTypes.func

}


function ModalSuccessMessageComponent(props) {
  const {
    openModal,
    translate,
    handleToggleModal,
    handleProceedClick,
    title
  } = props;
  return(
    <Modal isOpen={openModal} toggle={handleToggleModal}
           className={'modal-primary'}>
      <ModalHeader toggle={handleToggleModal}>{title}</ModalHeader>
      <ModalBody className="text-center">
        <h2>{translate('Saved successfully')}</h2>
        <div className="display-4">
          <i className="fa fa-check-circle text-success"></i>
        </div>
      </ModalBody>
      <ModalFooter className="justify-content-center">
        <Button
          type="button"
          color="primary"
          size="md"
          block={false}
          onClick={handleProceedClick}>
          <i className="fal fa-arrow-circle-right"></i>&nbsp;
          {translate('Proceed')}
        </Button>
      </ModalFooter>
    </Modal>
  );
}


export {ModalSuccessMessageComponent as ModalSuccessMessage};
