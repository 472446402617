/**
InputSelectWithLabel
Input select field Component with field error message
@functions:InputSelectWithLabelComponent
*/
// Core react dependencies
import React from 'react';
import PropTypes from 'prop-types';

/** UI components **/
import { Col, Input, FormGroup, Label } from 'reactstrap';

// Custom modules/components

import { removeAccent } from '../../_helpers';
import { FormFieldErrorText } from './FormFieldErrorText';

//To sort the options
function sortOptions(options, optionLabelFields, sortField){
      let labelKey  =  sortField;
       if(optionLabelFields.length > 0 && sortField !== 'name')
        labelKey = optionLabelFields[0];
      options.sort(function(a, b){
         if (removeAccent(a[labelKey]) > removeAccent(b[labelKey]))
          return 1
        else if(removeAccent(a[labelKey]) < removeAccent(b[labelKey]))
          return -1;
        else
          return 0;
      });
      return options;
}

//To render each option in the selectbox
function getOptions(options, value, optionLabelFields, sortField) {
  const optionComponents = [];
  sortOptions(options, optionLabelFields, sortField).map(function(option,i){
    let label = '';
    if(optionLabelFields.length > 0){
      optionLabelFields.map(labelField =>{
        if(labelField === 'translationLabel')
          label += option.translations[0].name
        else
        label += option[labelField];
        return true;
      });
    }
    else{
      label = option.name
    }

    optionComponents.push(<option key={i} value={option.id}>
        {label}
    </option>)
    return false;
  });
  return optionComponents;
}

InputSelectWithLabelComponent.propTypes = {
    name: PropTypes.string,
    id: PropTypes.string,
    label: PropTypes.string,
    optionLabelFields:PropTypes.array,
    value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.object
    ]),
    onChange: PropTypes.func,
    required: PropTypes.bool,
    errorMessages: PropTypes.array,
    inline: PropTypes.bool,
    disabled: PropTypes.bool,
    isDefaultOptionSet:PropTypes.bool,
    showLabel:PropTypes.bool,
    sortField:PropTypes.string
}

InputSelectWithLabelComponent.defaultProps = {
  optionLabelFields:[],
  errorMessages: [],
  inline: false,
  required: false,
  isDefaultOptionSet:false,
  showLabel:true,
  sortField:'name'
}
function InputSelectWithLabelComponent(props) {
  const {
    name,
    id,
    label,
    optionLabelFields,
    value,
    options,
    onChange,
    errorMessages,
    required,
    inline,
    disabled,
    isDefaultOptionSet,
    showLabel,
    sortField
  } = props;
  return (

    <div className="mb-3">
      {inline &&
        <FormGroup row>
          <Col md="3">
            <Label htmlFor={name}>
              {label}
              {required &&
                <span className="text-danger">*</span>
              }
            </Label>
          </Col>
          <Col xs="12" md="9">
            <Input disabled={disabled} type="select" name={name} id={id} onChange={onChange} value={value}>

              {
                ((options.filter(option => option.id === 0).length <= 0) && !isDefaultOptionSet) &&
                <option key="0" value="0">Please select</option>
              }
                {getOptions(options, value, optionLabelFields, sortField)}
            </Input>
            {errorMessages && errorMessages.length > 0 &&
              <FormFieldErrorText messages={errorMessages} field={id}  />
            }
          </Col>
        </FormGroup>
      }
      {!inline &&
        <FormGroup>
          {showLabel &&
            <Label htmlFor={name}>
              {label}
              {required &&
                <span className="text-danger">*</span>
              }
            </Label>
          }
          <Input disabled={disabled}  type="select" name={name} id={id} onChange={onChange} value={value}>
            {
              ((options.filter(option => option.id === 0).length <= 0) && !isDefaultOptionSet) &&
              <option key="0" value="0">Please select</option>
            }
              {getOptions(options, value, optionLabelFields, sortField)}
          </Input>
          {errorMessages && errorMessages.length > 0 &&
            <FormFieldErrorText messages={errorMessages} field={id}  />
          }
        </FormGroup>
      }


    </div>
  );
}

export {InputSelectWithLabelComponent as InputSelectWithLabel};
