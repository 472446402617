/**
InputRadioButtonWithLabel
Input text field Component with field error message
@functions:InputRadioButtonWithLabelComponent
*/
// Core react dependencies
import React from 'react';
import PropTypes from 'prop-types';

/** UI components **/
import { Input, FormGroup, Label } from 'reactstrap';


InputRadioButtonWithLabelComponent.propTypes = {
    name: PropTypes.string,
    id: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.object
    ]),
    onChange: PropTypes.func,
    checked: PropTypes.bool,
    inline: PropTypes.bool,
    disabled: PropTypes.bool
}

InputRadioButtonWithLabelComponent.defaultProps = {
  checked: false,
  disabled: false,
  inline: false
}
function InputRadioButtonWithLabelComponent(props) {
  const {
    name,
    id,
    label,
    value,
    onChange,
    checked,
    disabled,
    inline
  } = props;
  return (

    <div className="mb-3">
      {inline &&
        <FormGroup check inline>
          <Input
          className="form-check-input"
          type="radio"
          id={id}
          name={name}
          value={value}
          checked={checked}
          disabled={disabled}
          onChange = {onChange}
          />
          <Label
          className="form-check-label"
          check htmlFor={id}>
          {label}
          </Label>
        </FormGroup>
      }
      {!inline &&
        <FormGroup check className="radio">
          <Input
          className="form-check-input"
          type="radio"
          id={id}
          name={name}
          value={value}
          checked={checked}
          disabled={disabled}
          onChange = {onChange}
          />
          <Label
          className="form-check-label"
          check htmlFor={id}>
          {label}
          </Label>
        </FormGroup>
      }


    </div>
  );
}

export {InputRadioButtonWithLabelComponent as InputRadioButtonWithLabel};
