/**
access: array
To define the access for the component blocks
**/


export const BLOCK_ACCESS_RULES = [
  { block_name: 'SYSTEM_NOTIFICATION_VIEW_USERSBLOCK', allowed_roles:['admin','sysadmin'] },
  { block_name: 'SYSTEM_NOTIFICATION_SEND_TO_ALL', allowed_roles:['admin','sysadmin'] },
  { block_name: 'FORM_SLUG', allowed_roles:['sysadmin'] },
  { block_name: 'ITEM_SETTING', allowed_roles:['admin', 'sysadmin'] },
  { block_name: 'SYSTEM_NOTIFCATION_SEND_NOTIFICATION', allowed_roles:['admin','sysadmin'] },
  { block_name: 'ITEM_OWNER', allowed_roles:['admin','sysadmin','business_admin'] },
  { block_name: 'ITEM_REFRESH_CACHE', allowed_roles:['admin','sysadmin'] },
  { block_name: 'ITEM_LINK_PACE_BUTTON', allowed_roles:['admin','sysadmin'] },
  { block_name: 'ITEM_REQUEST_APPROVAL', allowed_roles:['admin','sysadmin','business_admin'] },
  { block_name: 'ITEM_APPROVE', allowed_roles:['admin','sysadmin'] },
  { block_name: 'ITEM_REJECT', allowed_roles:['admin','sysadmin'] },
  { block_name: 'DASHBOARD_ITEM_LIST', allowed_roles:['admin','sysadmin','business_admin'] },
  { block_name: 'DASHBOARD_SUMMARY_ANALYTICS', allowed_roles:['admin','sysadmin','business_admin','analyst'] },
  { block_name: 'TICKET_ASSIGNED_TO', allowed_roles:['admin','sysadmin'] },
  { block_name: 'TICKET_OPEN', allowed_roles:['admin','sysadmin','business_admin'] },
  { block_name: 'TICKET_CLOSE', allowed_roles:['admin','sysadmin'] },
  { block_name: 'TICKET_RESOLVE', allowed_roles:['admin','sysadmin','business_admin'] },
  { block_name: 'TICKET_CANCEL', allowed_roles:['business_admin'] },
  { block_name: 'TOP_10_CATEGORY', allowed_roles:['admin','sysadmin'] }
];
