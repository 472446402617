/**
Constants to manage the translation
*/

export const availableLanguages = [
  { code: 'fr', name: 'French', sort_order:0},
  { code: 'en', name: 'English', sort_order:1 },
  { code: 'am', name: 'Amharic', sort_order:2 },
  { code: 'ar', name: 'Arabic', sort_order:3 },
  { code: 'es', name: 'Spanish', sort_order:4 },
  { code: 'mg', name: 'Malgache', sort_order:5 },
  { code: 'pt', name: 'Portuguese', sort_order:6 }
];
export const fallbackLocale = process.env.REACT_APP_FALLBACK_LOCALE;
export const defaultLocale = process.env.REACT_APP_DEFAULT_LOCALE;
