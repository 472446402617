/**
httpActions
To dispatch the http action for asynch request
@functions:
initHTTP,
throwError
*/


export const httpActions = {
    initHTTP,
    throwError
};


/**
* initHTTP
* To initialize the http request
*/
function initHTTP() {
    return { type: 'HTTP_REQUEST_INIT'};
}

/**
* failure
* To simulate http failure
*/
function throwError(payload) {
    return { type: 'HTTP_REQUEST', payload:payload};
}
