/**
AuthActivate
The alertbar to be shown at the top of the page when account not activated
*/

// Core react dependencies
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// UI components
import {Alert, Button } from 'reactstrap';

// Translation
import { getTranslate, getActiveLanguage, getLanguages } from 'react-localize-redux';

//Custom module dependencies
import { translateActions } from '../../_services/actions';
import { authActions } from '../../_pages/Auth/actions/auth';
import Timeout from './Timeout'

class AuthActivateMessage extends React.Component {
    static propTypes = {
      user: PropTypes.object
    }
    constructor(props) {
        super(props);
        this.state = {
            visible:true,
            alert:null
        };

        //to setup the translation
        this.props.dispatch(
          translateActions.addTranslationForPage(
          this.props.languages,
          'Auth',
          'ActivationAlert'
          )
        );
        this.handleDismiss = this.handleDismiss.bind(this);

    }
    /**
    * handleClick
    * when account activation link is clicked
    */
    handleClick = (event) => {
        this.props.dispatch(authActions.sendActivationLink(this.onActivationLinkSentSuccess, this.onHttpFailure));
    }

    /**
    * when the activation link sent successfully
    * @param {Object} response
    */
    onActivationLinkSentSuccess=(response)=>{
      let alert={
        'type':'success',
        'message':response.message
      }
      this.setState({
        alert:alert
      });
    }
    /**
    * when http request failed
    * @param {Object} error
    */
    onHttpFailure=(response)=>{
      let error = response.json;
      let alert ={
        'type':'error',
        'message':error.message?error.message:'',
        'description':error.description?error.description:''
      }
      this.setState({
        alert:alert
      });
    }

    /**
    * handleDismiss
    * to close the alert
    */
    handleDismiss() {
      this.setState(
        {
           visible:false
        },
        () => {
        this.props.clearTimeouts();
      });

    }

    componentDidUpdate(){
      // to check for state if activation email was sent to change to hide the alert bar
      if(this.props.alert  && this.props.alert.type === 'success' && this.state.visible === true){
        this.props.setTimeout(() => {
          this.handleDismiss()
        }, 2000)
      }
      // To adjust position of header and sidebar if alert bar is visible
      let app_element = document.getElementById('root');
      if(this.props.user && !this.props.user.activated && this.state.visible)
       app_element.classList.add('with-message-bar');
      else
       app_element.classList.remove('with-message-bar');
    }

    render() {
        const {user, translate} = this.props;
        const {visible, alert} = this.state;
        let AccountNotActivatedAlert = () => {
          const AlertBar =
          <Alert className="header-alert" color="warning" isOpen={this.state.visible} toggle={this.handleDismiss}>
            {translate('To send the activation link')} &nbsp;
             <Button color="link" onClick={this.handleClick} >
               {translate('Click here')}
             </Button>
          </Alert>
          if(visible)
            return AlertBar;
          else
            return null;
        }
        let AccountActivationLinkSentAlert= () => {
          const AlertBar =
            <Alert className="header-alert" color="success" isOpen={this.state.visible} toggle={this.handleDismiss}>
              {translate('Your account activation link sent.')}
            </Alert>
          if(visible)
            return AlertBar;
          else
            return null;
        }
        let AccountActivationErrorAlert= () => {
          const AlertBar=
          <Alert className="header-alert" color="danger" isOpen={this.state.visible} toggle={this.handleDismiss}>
            {alert.message.description}
          </Alert>
          if(visible)
            return AlertBar;
          else
            return null;
        }
        if(alert && alert.type === 'success') {
          return <AccountActivationLinkSentAlert />;
        }
        else if(alert && alert.type === 'error')
          return <AccountActivationErrorAlert />;
        else if(user && !user.activated)
          return <AccountNotActivatedAlert />;
        else
          return null;

    }
}


const mapStateToProps = state => (
  {
    languages: getLanguages(state.localize),
    translate: getTranslate(state.localize),
    activeLanguage: getActiveLanguage(state.localize).code,
    user:state.authentication.user
  }
);
AuthActivateMessage = Timeout(AuthActivateMessage);
const connectedAuthActivate = connect(mapStateToProps)(AuthActivateMessage)
export { connectedAuthActivate as AuthActivateMessage };
