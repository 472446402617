/**
BreadCrumb
To show the breadcrumbs per page
*/

// Core react dependencies
import React from 'react';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// UI components
import { Button, Breadcrumb, BreadcrumbItem } from 'reactstrap';

// Translation
import { getTranslate, getActiveLanguage, getLanguages } from 'react-localize-redux';

//Custom module dependencies
import { translateActions, layoutActions } from '../../_services/actions';
import { urlConfig } from '../../_config';

class BreadCrumb extends React.Component {
    static propTypes = {
      appRoutes: PropTypes.array
    }
    constructor(props) {
        super(props);

        //to setup the translation
        this.props.dispatch(
          translateActions.addTranslationForPage(
          this.props.languages,
          'Layout',
          'Breadcrumb'
          )
        );

    }
    showBreadCrumbs() {
      const {appRoutes, translate, activeLanguage, show_settings_icon} = this.props;
      let breadCrumbItems= [];
      appRoutes.map(function(appRoute, index){
        if(appRoute.path === '#')
          breadCrumbItems.push(<BreadcrumbItem key={index} active>
            {(appRoute.translate === false) &&
              <span>  {appRoute.name} </span>
            }
            {(appRoute.translate === undefined || appRoute.translate === true) &&
              <span> {translate(appRoute.name)} </span>
            }
            </BreadcrumbItem>);
        else
          breadCrumbItems.push(<BreadcrumbItem key={index} >
            {(appRoute.translate === false) &&
            <Link to={`${urlConfig.BASE_URL}/${activeLanguage}${appRoute.path}`}>{appRoute.name}</Link>
            }
            {(appRoute.translate === undefined || appRoute.translate === true) &&
            <Link to={`${urlConfig.BASE_URL}/${activeLanguage}${appRoute.path}`}>{translate(appRoute.name)}</Link>
            }
            </BreadcrumbItem>);
        return true;
      });
      return (
        <Breadcrumb>
            {breadCrumbItems}
            {show_settings_icon &&
            <div className="ml-auto">
              <Button
              color="link"
              onClick={this.showSettingsSideBar}>
              <i className="far fa-cog"></i>
              </Button>
            </div>
            }
        </Breadcrumb>
      );
    }



    showSettingsSideBar=()=>{
      if(this.props.show_settings_aside_bar)
      this.props.dispatch(layoutActions.hideSettingsAsideBar());
      else
      this.props.dispatch(layoutActions.showSettingsAsideBar());
    }


    render() {
        return(
        <div>
          {this.showBreadCrumbs()}
        </div>
     );
    }
}


const mapStateToProps = state => (
  {
    languages: getLanguages(state.localize),
    translate: getTranslate(state.localize),
    activeLanguage: getActiveLanguage(state.localize).code,
    show_settings_aside_bar:state.layout.show_settings_aside_bar,
    show_settings_icon:state.layout.show_settings_icon
  }
);
BreadCrumb = connect(mapStateToProps)(BreadCrumb)
export { BreadCrumb };
