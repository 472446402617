/**
settings
The reducer function to define the settings of dashboard
*/
const initialState = { show_slug:false };
export function settings(state = initialState, action) {
  switch (action.type) {
    case 'SHOW_SLUG':
      state.show_slug = true;
      return state;
    case 'HIDE_SLUG':
      state.show_slug = false;
      return state;
    default:
      return state
  }
}
