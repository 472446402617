/**
Cube JS authentication
The reducer function to define state for authentication requests
*/

let mysql_token = localStorage.getItem('mysql_token');
let bigquery_token = localStorage.getItem('bigquery_token');
const initialState =  {mysql:mysql_token, bigquery:bigquery_token, error:null};
export function cubejs_authentication(state = initialState, action) {
  switch (action.type) {
    case 'CUBEJS_AUTH_LOGIN_SUCCESS':
      return {mysql:action.payload.mysql_token, bigquery:action.payload.bigquery_token, error:null};
    case 'CUBEJS_AUTH_LOGIN_FAILURE':
      return {mysql:null, bigquery:null, error:null};
    default:
      return state;
  }
}
