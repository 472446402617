/**
layoutActions
To dispatch the layout actions to handle layout actions
@functions:setBreadcrumb
*/


export const layoutActions = {
    initBreadcrumb,
    setBreadcrumb,
    showSectionAsideBar,
    hideSectionAsideBar,
    setSectionAsideBarData,
    setAdditionalInfoBlockData,
    showLoader,
    hideLoader,
    showSettingsAsideBar,
    hideSettingsAsideBar,
    showSettingsIcon,
    hideSettingsIcon
};
/**
* initBreadcrumb
* To initialize the breadcrumbs
*/
function initBreadcrumb() {
  return { type: 'SET_BREADCRUMB', payload:[] }
}

/**
* setBreadcrumb
* To set the breadcrumbs
*/
function setBreadcrumb(breadcrumbs) {
 return { type: 'SET_BREADCRUMB', payload:breadcrumbs }
}


/**
* showSectionAsideBar
* To show the Asidebar
*/
function showSectionAsideBar() {
  return { type: 'SHOW_ASIDEBAR'}
}

/**
* hideSectionAsideBar
* To hide the Asidebar
*/
function hideSectionAsideBar() {
 return { type: 'HIDE_ASIDEBAR'}
}

/**
* setSectionAsideBarData
* To hide the Asidebar
*/

function setSectionAsideBarData(data) {
 return { type: 'SET_SECTION_ASIDEBLOCK_DATA',payload:data}
}

/**
* setAdditionalInfoBlockData
* To hide the Asidebar
*/

function setAdditionalInfoBlockData(data) {
 return { type: 'SET_ADDITIONAL_INFO_BLOCK_DATA',payload:data}
}

/**
* showLoader
* To show the page loader
*/
function showLoader() {
 return { type: 'SHOW_PAGE_LOADER', payload:true }
}


/**
* hideLoader
* To hide the page loader
*/
function hideLoader() {
 return { type: 'HIDE_PAGE_LOADER', payload:false }
}


/**
* showSettingsAsideBar
* To show the settings asidebar
*/
function showSettingsAsideBar() {
  return { type: 'SHOW_SETTING_ASIDEBAR', payload:true}
}

/**
* hideSettingsAsideBar
* To hide the Asidebar
*/
function hideSettingsAsideBar() {
 return { type: 'HIDE_SETTING_ASIDEBAR'}
}


/**
* showSettingsIcon
* To show the settings asidebar
*/
function showSettingsIcon() {
  return { type: 'SHOW_SETTING_ICON', payload:true}
}

/**
* hideSettingsIcon
* To hide the settings icon
*/
function hideSettingsIcon() {
 return { type: 'HIDE_SETTING_ICON'}
}
