//Core react libs
import React, {Component} from 'react';
import { connect } from 'react-redux';


//HTML editor
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// Translation
import {getTranslate, getLanguages, getActiveLanguage } from 'react-localize-redux';

//UI components
import { FormGroup, Col } from 'reactstrap';

// Local custom components/libs
import {
   InputTextFieldWithLabel
} from '../InputTextFieldWithLabel'

import {
   RichTextField
} from '../RichTextField'


/** General information card **/
class FieldsWithOutTab extends Component {

  /**
  * When input fields value changes
  * @param {Object} event
  * @returns {boolean}
  */
  handleChange = (langCode, field, event) => {
      if(field.max_characters !== null){
        const {value} = event.target;
        if(value.length <= field.max_characters){
          this.props.handleChange(langCode, field.name, event)
          return true;
        }
        else{
          return false;
        }
      }
      else{
        this.props.handleChange(langCode, field.name, event);
        return true;
      }
  }

  /**
  * getFieldErrorMessages
  * To get the error messages for each field
  * @param [field]
  * @param  {Object}[errorBag] - error messages
  */
  getFieldErrorMessages =(field)=>{
    let httpErrorsExist = false;
    if(this.props.alert && this.props.alert.errors){
      let httpFieldErrors = this.props.alert.errors;
      if(httpFieldErrors && httpFieldErrors[field] && httpFieldErrors[field].length > 0){
        httpErrorsExist = true;
        return httpFieldErrors[field];
      }
      else
        httpErrorsExist = false;
    }
    if(!httpErrorsExist)
      return this.props.errorBag.field_validation_messages;
  }

  /**
  * To render the component
  * @returns  {Object} HTML element
  */
  render() {
    const { languages, translate, data, fields } = this.props;
    let lang = languages[0];
    return (
          <FormGroup row>
              <Col xs="12">
                  {
                          fields.map((field,fieldkey) => {
                            if(field.type === 'html')
                              return(
                                <ReactQuill
                                key={`${lang.code}${field}`}
                                value = {data.translations[lang.code][field.name]}
                                onChange = {(value) => this.handleChange(lang.code, field, value)}
                                />
                             )

                            else if(field.type === 'textarea')
                              return(
                                <div key={`${lang.code}${field}`}>
                                  <InputTextFieldWithLabel
                                  key = {`translation_${lang.code}_${field.name}`}
                                  type = "textarea"
                                  name = ""
                                  id = {`translation_${lang.code}_${field.name}`}
                                  rows="5"
                                  value = {data.translations[lang.code][field.name]}
                                  label={translate(field.name)}
                                  placeholder={translate("placeholder_"+field.name)}
                                  required={field.required}
                                  onChange = {(e) => this.handleChange(lang.code, field, e)}
                                  errorMessages = {this.getFieldErrorMessages(`translation_${lang.code}_${field.name}`)}
                                  >
                                  <div className="text-right">{data.translations[lang.code][field.name].length} / {field.max_characters}</div>
                                  </InputTextFieldWithLabel>
                                </div>
                             )
                             else if(field.type === 'richtext')
                               return(
                                 <div key={`${lang.code}${field.name}`}>
                                   <RichTextField

                                   placeholder={translate("placeholder_"+field.name)}
                                   value = {data.translations[lang.code][field.name]}
                                   onChange = {(e) => this.handleChange(lang.code, field, e)} />
                                 </div>
                              )
                            else
                            return(
                              <InputTextFieldWithLabel
                              key = {`translation_${lang.code}_${field.name}`}
                              type = "text"
                              name = ""
                              id = {`translation_${lang.code}_${field.name}`}
                              value = {data.translations[lang.code][field.name]}
                              label={translate(field.name)}
                              placeholder={translate("placeholder_"+field.name)}
                              required={field.required}
                              onChange = {(e) => this.handleChange(lang.code, field, e)}
                              errorMessages = {this.getFieldErrorMessages(`translation_${lang.code}_${field.name}`)}
                           />
                           )
                        })
                    }
              </Col>
        </FormGroup>
    );
  }
}



function mapStateToProps(state) {
    return {
        translate: getTranslate(state.localize),
        languages: getLanguages(state.localize),
        activeLanguage: getActiveLanguage(state.localize).code,
        loading: state.http_request.sending,
        alert: state.http_request.response,
    };
}

FieldsWithOutTab = connect(mapStateToProps)(FieldsWithOutTab);
export {FieldsWithOutTab};
