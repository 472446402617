/**
pronostic: to define the pronostics module Constants
*/

//Match phases
export const MATCH_PHASES = [
  {
    "id":"GROUP",
    "name":"GROUP"
  },
  {
    "id":"CHAMPIONSHIP",
    "name":"CHAMPIONSHIP"
  },
  {
    "id":"SIXTEENTHFINAL",
    "name":"SIXTEENTHFINAL"
  },
  {
    "id":"EIGHTHFINAL",
    "name":"EIGHTHFINAL"
  },
  {
    "id":"QUARTERFINAL",
    "name":"QUARTERFINAL"
  },
  {
    "id":"SEMIFINAL",
    "name":"SEMIFINAL"
  },
  {
    "id":"FINAL",
    "name":"FINAL"
  }
];


//Match groups
export const GROUPS = [
  {
    "id":"A",
    "name":"A"
  },
  {
    "id":"B",
    "name":"B"
  },
  {
    "id":"C",
    "name":"C"
  },
  {
    "id":"D",
    "name":"D"
  },
  {
    "id":"E",
    "name":"E"
  },
  {
    "id":"F",
    "name":"F"
  },
  {
    "id":"G",
    "name":"G"
  },
  {
    "id":"H",
    "name":"H"
  }
];


//participants count
export const PARTICIPANTS ={
  "count":2
}

//bet type
export const GAME_SCORE_BET_TYPE = 'game-score'
export const GAME_SCORE_WINNER_BET_TYPE = 'game-score-winner'
