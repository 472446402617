/**
groupActions
To dispatch the account actions
@functions:
getGroups,
*/

import { urlConfig } from '../../../_config';

import { onHttpFailure } from '../../../_helpers';
import HttpRequest from '../../../_services/http/request';
const api = new HttpRequest();
export const groupActions = {
    getGroupList,
    getGroup,
    updateGroup,
    deleteGroup,
    createGroup
};

/**
* getGroupList
* To dispatch the get user list request
* @param  {Object} reducer [reducer with action type based on success or error response from the API]
*/

function getGroupList(dispatch, onSuccess, onFailure){
  api.get(urlConfig.API_URL+'account/groups').then(
      data => {
        onSuccess(data);
      },
      error => {
        onHttpFailure(error, dispatch, onFailure);
      }
  );
}

/**
* getGroup detail
* To dispatch the getGroup request
* @param  {Object} reducer [reducer with action type based on success or error response from the API]
*/

function getGroup(id, dispatch, onSuccess, onFailure){
  api.get(urlConfig.API_URL+'account/groups/'+id).then(
      data => {
        onSuccess(data);
      },
      error => {
        onHttpFailure(error, dispatch, onFailure);
      }
  );
}
/**
* createGroup detail
* To dispatch the create group request
* @param  {Object} reducer [reducer with action type based on success or error response from the API]
*/

function createGroup(data, onHttpSuccess){
  return (dispatch, _, api) => {
    dispatch(
      {
        type: 'HTTP_REQUEST',
        promise: api
          .post(urlConfig.API_URL+'account/groups/', data),
        meta: {
            onFailure: (response, getState) => {
              onHttpFailure(response,dispatch);
            },
            onSuccess: (response, getState) => {
              onHttpSuccess(response);
            }
        }
      }
    );
  };
}

/**
* updateGroup detail
* To dispatch the updateGroup request
* @param  {Object} reducer [reducer with action type based on success or error response from the API]
*/

function updateGroup(id,data){
  return (dispatch, _, api) => {
    dispatch(
      {
        type: 'HTTP_REQUEST',
        promise: api
          .put(urlConfig.API_URL+'account/groups/'+id, data)
      }
    );
  };
}


/**
* deleteGroup
* To dispatch the deleteGroup request
* @param  {Object} reducer [reducer with action type based on success or error response from the API]
*/
function deleteGroup(user_id){
  return (dispatch, _, api) => {
    dispatch(
      {
        type: 'HTTP_REQUEST',
        promise: api
          .delete(urlConfig.API_URL+'account/groups/'+user_id)
      }
    );
  };
}
