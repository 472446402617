/**
sessionActions
To dispatch the session actions to set the session token of the logged in user
@functions:setSession,renewToken
*/

import moment from 'moment';


import { authActions } from './auth';
import { urlConfig } from '../../_config';

export const sessionActions = {
    setSession,
    renewToken
};

function setSession(userResponse) {
  return (dispatch, getState, api) => {
    const expiresIn = userResponse.expires_in;
    if(!expiresIn)
     return dispatch(authActions.logout());
    else{
      const tokenExpiryDate = moment().add(expiresIn,'s');
      const minutesBeforeExpiry = tokenExpiryDate.subtract(1,'m');
      if (moment() >  minutesBeforeExpiry) { //If the token has expired or will expire in the next 10 minutes
          return authActions.logout();
      }
      let difference = minutesBeforeExpiry.diff(moment());
      if(difference > 2147483647){
        difference = 2147483647
      }
      setTimeout( () => dispatch( renewToken()),difference);
      // to set the localStorage
      localStorage.setItem('access_token', userResponse.access_token);
      localStorage.setItem('refresh_token', userResponse.refresh_token);
      localStorage.setItem('expires_in', expiresIn);

      //Cube js authentication
      let data = {
        'token':userResponse.access_token
      };
      api.post(urlConfig.CUBE_API_URL+'/authenticate', data).then(
          response => {
            dispatch(successCubeJSAuthentication(response.data));
          },
          error => {
            api.post(urlConfig.CUBE_API_URL+'/authenticate', data).then(
                response => {
                  dispatch(successCubeJSAuthentication(response.data));
                },
                error => {
                  dispatch(failureCubeJSAuthentication(error))
                }
            );
          }
      );


      let payload={};
      payload.token_expires_in = expiresIn;
      dispatch(tokenRefreshSuccess());
    }
  };
}
function successCubeJSAuthentication(payload) { return { type: 'CUBEJS_AUTH_LOGIN_SUCCESS', payload } }
function failureCubeJSAuthentication(payload) { return { type: 'CUBEJS_AUTH_LOGIN_FAILURE', payload } }

function renewToken() {
  return (dispatch, getState, api) => {
    dispatch(tokenRefreshRequest());
    let refresh_token = localStorage.getItem('refresh_token');
    if(!refresh_token)
     dispatch(authActions.logout());
    else{
      api.post(urlConfig.API_URL+'oauth/token/refresh',
      {refresh_token})
          .then(
              data => {
              //  dispatch(tokenRefreshSuccess());
                dispatch(setSession(data.data));
              },
              error => {
                console.log('eror token refresh');
                dispatch(authActions.logout());
              }
          );
    }
  };
}



function tokenRefreshRequest() {
   return { type: 'AUTH_TOKEN_REFRESH_REQUEST' }
}
function tokenRefreshSuccess() {
   return { type: 'AUTH_TOKEN_REFRESH_SUCCESS' }
}
