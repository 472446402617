/**
* Utility functions for Responsive table
**/
/**
* Remote pagination, sorting, searching
* @param {Object} sortParams
* @param {Object} pageParams
* @param {Object} searchParams
*/
export function getTableDataQueryParams(sortParams={}, pageParams={}, searchParams={}, filterParams=[], searchWithFilter=false){
  let searchQueryParams = '';
  if(searchWithFilter){
    //Search and filter
    if(filterParams && filterParams.length > 0){
      let filterSearchParams = '';
      let filterSearchFields = '';
      filterParams.map((filter, filter_key) => {
        if(filter.value && parseInt(filter.value) !== 0){
          filterSearchParams += filter.field+':'+filter.value+';';
          filterSearchFields += filter.field+':'+filter.condition+';';
        }
        return true;
      });
      searchQueryParams = 'searchJoin=and&search=';
      searchQueryParams += (filterSearchParams)?filterSearchParams:';';
      searchQueryParams += (searchParams.query)?searchParams.query:'';

      searchQueryParams += '&searchFields=';
      searchQueryParams += (filterSearchFields)?filterSearchFields:'';
      searchQueryParams += (searchParams.searchFields)?searchParams.searchFields:'';
      searchQueryParams += '&';

    }
  }
  else{
    //Either search or filter
    if(searchParams && searchParams.query && searchParams.query !== ''){
      searchQueryParams += (searchParams.query)?'search='+searchParams.query+'&':'';
      if(searchParams.searchFields && searchParams.searchFields !== '' )
        searchQueryParams += 'searchFields='+searchParams.searchFields+'&';
    }
    else if(filterParams && filterParams.length > 0){
      let searchParams = '';
      let searchFields = '';
      filterParams.map((filter, filter_key) => {
        if(filter.value && filter.value !== 0){
          if((filter_key+1) !== filterParams.length){
            searchParams += filter.field+':'+filter.value+';';
            searchFields += filter.field+':'+filter.condition+';';
          }
          else{
            searchParams += filter.field+':'+filter.value;
            searchFields += filter.field+':'+filter.condition;
          }
        }
        return true;
      });
      searchQueryParams += (searchParams)?'search='+searchParams+'&':'';
      if(searchFields)
        searchQueryParams += 'searchFields='+searchFields+'&';
    }
  }


  let sortQueryParams = '';
  if(sortParams && sortParams.name){
    let orderBy = sortParams.name;
    let sortBy = sortParams.order;
    sortQueryParams += (orderBy)?'orderBy='+orderBy+'&':'';
    sortQueryParams += (sortBy)?'sortBy='+sortBy+'&':'';
  }

  let pageQueryParams = '';
  if(pageParams && pageParams.perPage){
    let perPage = pageParams.perPage;
    let page = pageParams.currentPage;
    pageQueryParams += (perPage)?'per_page='+perPage+'&':'';
    pageQueryParams += (page)?'page='+page+'&':'';
  }

  return searchQueryParams+sortQueryParams+pageQueryParams;
}
