/**
ticketActions
To dispatch the ticket actions
*/

import { urlConfig } from '../../_config';

import { onHttpFailure } from '../../_helpers';
import HttpRequest  from '../http/request';
const api = new HttpRequest();
export const ticketActions = {
    getUnseenCount
};


/**
* getUnseenCount
* To dispatch the getUnseenCount request
* @param  {Object} dispatch [reducer with action type based on success or error response from the API]
* @param  {function} onSuccess
* @param  {function} onFailure
*/
function getUnseenCount(dispatch, onSuccess, onFailure){
  api.get(urlConfig.API_URL+'ticket_management/issues/unseen-count').then(
      data => {
        onSuccess(data);
      },
      error => {
        onHttpFailure(error, dispatch, onFailure);
      }
  );
}
