/**
FormErrors
Component to display field validation errors
@functions:FormFieldValidationError
*/
import React from 'react';
import PropTypes from 'prop-types';

FormFieldErrorText.propTypes = {
    messages: PropTypes.array,
    field: PropTypes.string
}

FormFieldErrorText.defaultProps = {
  messages: []
}
function FormFieldErrorText(props){
  const {
    messages,
    field
  } = props;

  function getMessageLabel(messages, field) {
    const Labels = []
    messages.filter(message => message.field === field).map(function(message,i){
      if(message.type === 'warning')
        Labels.push(<div key={i} className="text-warning mb-1">{message.description} </div>)
      else
        Labels.push(<div key={i} className="text-danger mb-1">{message.description} </div>)
      return false;
    });
    return Labels;
  }

  function Messages(props) {
    if(messages.filter(message => message.field === props.field).length > 0){
     return getMessageLabel(messages, field);
    }
    else
      return null;
  }

  if(messages.filter(message => message.field === field).length > 0)
    return <Messages messages={messages} field={field} />
  else
    return null;

}

export { FormFieldErrorText } ;
