import { createBrowserHistory } from 'history';
import { urlConfig, availableLanguages, redirectionConfig } from '../_config';
import * as queryString from 'query-string';

export const history = createBrowserHistory();

// To set the last url to redirect after login
export function setLastUrl(url,props) {
    //To set the last URL
    let langCodeFound = false;
    let urlReplaced='';
    availableLanguages.map((lang,index)=>{
      if(url.indexOf(`${urlConfig.BASE_URL}/${lang.code}`,'') >= 0){
        urlReplaced = url.replace(`${urlConfig.BASE_URL}/${lang.code}`,'');
        langCodeFound = true;
      }
      return true;
    });


    let urlsToSkip = redirectionConfig.SKIP_URLS_TO_REDIRECT_AFTERLOGIN;
    if(langCodeFound && !urlsToSkip.includes(urlReplaced)){
      localStorage.setItem('last_url',url);
    }

    // To redirect to login if there is no user in the session
    let currentUrlToSkip = redirectionConfig.URLS_TO_VISIT_WITHOUT_LOGIN.filter(url=> history.location.pathname.search(url) !== -1);
    if(props.user === null && currentUrlToSkip.length === 0){
      let email_token = queryString.parse(props.location.search).et;
      if(email_token){
        history.push(`${urlConfig.BASE_URL}/account/login?et=${email_token}`);
      }
      else
        history.push(`${urlConfig.BASE_URL}/account/login`);
    }
}
// To get the last url to redirect after login
export function getLastUrl(){
  return localStorage.getItem('last_url');
}
