/**
http_request
The reducer function to define state for sucess/error responses
*/

import { handle } from 'redux-pack';

function createErrorResponse(payload){
	let error = {
			'message':String(payload),
			'description':String(payload),
			'type':'error',
			'status':'500'
	}
	return error;
}

const initialAsyncRequestState = { sending: false, type: null, response: null };

const reduceAsyncAction = (state, action) => handle(state, action, {
	start: state => ( { ...state, sending: true, type: null, response: null } ),
	failure: state => ( { ...state, sending: false, type: 'error', response: (action.payload && action.payload.json)?action.payload.json:createErrorResponse(action.payload) } ),
	success: state => ( { ...state, sending: false, type: 'success', response: action.payload } )
});

const makeAsyncActionReducer = (actionType, resetActionType = undefined) => (state = initialAsyncRequestState, action) => {
	if(!resetActionType)
		resetActionType = actionType+'_INIT';

	const { type } = action;
	switch (type) {
		case actionType:
			return reduceAsyncAction(state, action);
		case resetActionType:
			return initialAsyncRequestState;
		default:
			return state;
	}
};


export const http_request = makeAsyncActionReducer('HTTP_REQUEST');
