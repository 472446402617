/**
Constants to manage inline styles of the components
*/


export const selectSearchColourStyles = {
  control: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      color: '#5c6873',
      backgroundColor: '#fff',
      borderColor: (isFocused)?'#8ad4ee':'#e4e7ea',
      outline: 0,
      boxShadow: (isFocused)?'0 0 0 0.2rem rgba(32, 168, 216, 0.25)':'none'
    };
  },
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: (isFocused) ? '#f9f9f9' : (isSelected)?'#fff':'#fff',
      color:'#23282c',
    };
  },
};
