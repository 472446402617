/**
InputDateField
Input text field Component with field error message
@functions:InputTimeFieldComponent
*/
//CSS

import 'rc-time-picker/assets/index.css';

// Core react dependencies
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';


/** UI components **/
import { Col, FormGroup, Label } from 'reactstrap';
import TimePicker from 'rc-time-picker';

// Custom modules/components
import { FormFieldErrorText } from './FormFieldErrorText';

const now = moment().hour(0).minute(0);

InputTimeFieldComponent.propTypes = {
    name: PropTypes.string,
    id: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    errorMessages: PropTypes.array,
    inline: PropTypes.bool,
    required: PropTypes.bool
}

InputTimeFieldComponent.defaultProps = {
  errorMessages: [],
  inline: false,
  required: false
}

function InputTimeFieldComponent(props) {
  const {
    name,
    id,
    label,
    value,
    onChange,
    errorMessages,
    inline,
    disabled,
    required
  } = props;
  let defaultValue = now;
  if(value && value !== '')
    defaultValue = moment().hour(value.split(":")[0]).minute(value.split(":")[1]);
  else{
    onChange({
        target:{
          name:name,
          value:moment(defaultValue).format('HH:mm')
        }
    }, false);
  }
  return (
    <div  className="mb-3">
      {inline &&
        <FormGroup row>
          <Col md="3">
            <Label htmlFor={name}>
              {label}
              {required &&
                <span className="text-danger">*</span>
              }
            </Label>
          </Col>
          <Col xs="12" md="9">
          <TimePicker
            disabled={disabled}
            showSecond={false}
            defaultValue={defaultValue}
            onChange={(val)=>onChange({
                target:{
                  name:name,
                  value:moment(val).format('HH:mm')
                }
            })}
            format='HH:mm'
          />
        {errorMessages && errorMessages.length > 0 &&
              <FormFieldErrorText messages={errorMessages} field={id}  />
            }
          </Col>
        </FormGroup>
      }
      { !inline &&
        <FormGroup>
          <Label htmlFor={name}>
            {label}
            {required &&
              <span className="text-danger">*</span>
            }
          </Label>
          <TimePicker
            disabled={disabled}
            showSecond={false}
            defaultValue={defaultValue}
            onChange={(val)=>onChange({
                target:{
                  name:name,
                  value:moment(val).format('HH:mm')
                }
            })}
            format='HH:mm'
          />
          {errorMessages && errorMessages.length > 0 &&
            <FormFieldErrorText messages={errorMessages} field={id}  />
          }
        </FormGroup>
      }
    </div>
  );
}

export {InputTimeFieldComponent as InputTimeFieldWithLabel};
