/**
Category map icon configuration
*/
/** BigCITY **/
/*
export const CATEGORY_MAP_ICONS = [
  {
    key:"categories_accomodation"
  },
  {
    key:"categories_place"
  },
  {
    key:"categories_event"
  },
  {
    key:"categories_service"
  }
]

export const CATEGORY_COLORS = [
  {
    key:"red",
    hex:"#A82282"
  },
  {
    key:"blue",
    hex:"#2230A8"
  },
  {
    key:"green",
    hex:"#22A86A"
  },
  {
    key:"yellow",
    hex:"#FFFF00"
  }
]
*/
// SoCITY
/**
Categories: icons
*/
/* BigCITY
export const CATEGORY_ICONS = [
{
  key:"categories_going_out",
  icon_path:"M11.5,7.99998357 L2.50022059,8 C2.43321663,8 2.36689589,7.98661834 2.30519697,7.96064971 C2.05087169,7.85360605 1.93201537,7.56193139 2.03972394,7.30917613 C3.54809024,3.76973446 6.86818732,2 12.0000799,2 C17.1320151,2 20.4521247,3.76976383 21.9604087,7.30929148 C21.9865318,7.37059531 21.9999931,7.43649112 21.9999931,7.50306574 C21.9999931,7.77749579 21.7761417,7.99996531 21.500007,7.99996531 L12.5,7.99998174 L12.5,22 L11.5,22 L11.5,7.99998357 Z M21,17.5 L17.5,17.5 C17.2238576,17.5 17,17.7238576 17,18 L17,21.5 C17,21.7761424 16.7761424,22 16.5,22 C16.2238576,22 16,21.7761424 16,21.5 L16,17.5 C16,16.9477153 16.4477153,16.5 17,16.5 L21,16.5 L21,11.5 C21,11.2238576 21.2238576,11 21.5,11 C21.7761424,11 22,11.2238576 22,11.5 L22,21.5 C22,21.7761424 21.7761424,22 21.5,22 C21.2238576,22 21,21.7761424 21,21.5 L21,17.5 Z M3,16.5 L7,16.5 C7.55228475,16.5 8,16.9477153 8,17.5 L8,21.5 C8,21.7761424 7.77614237,22 7.5,22 C7.22385763,22 7,21.7761424 7,21.5 L7,18 C7,17.7238576 6.77614237,17.5 6.5,17.5 L3,17.5 L3,21.5 C3,21.7761424 2.77614237,22 2.5,22 C2.22385763,22 2,21.7761424 2,21.5 L2,11.5 C2,11.2238576 2.22385763,11 2.5,11 C2.77614237,11 3,11.2238576 3,11.5 L3,16.5 Z"
},
{
  key:"categories_event",
  icon_path:"M4,5 L20,5 C21.1045695,5 22,5.8954305 22,7 L22,10 L22,10 C20.8973631,10 20.0034993,10.8938638 20.0034993,11.9965007 L20.0034993,12.0040181 C20.0034993,13.106655 20.8973631,14.0005188 22,14.0005188 L22,17 C22,18.1045695 21.1045695,19 20,19 L4,19 C2.8954305,19 2,18.1045695 2,17 L2,14.0005188 L2,14.0005188 C3.10329925,14.0005188 3.99770001,13.106118 3.99770001,12.0028188 L3.99770001,11.9977 C3.99770001,10.8944008 3.10329925,10 2,10 L2,7 C2,5.8954305 2.8954305,5 4,5 Z"
},
{
  key:"categories_events",
  icon_path:"M4,5 L20,5 C21.1045695,5 22,5.8954305 22,7 L22,10 L22,10 C20.8973631,10 20.0034993,10.8938638 20.0034993,11.9965007 L20.0034993,12.0040181 C20.0034993,13.106655 20.8973631,14.0005188 22,14.0005188 L22,17 C22,18.1045695 21.1045695,19 20,19 L4,19 C2.8954305,19 2,18.1045695 2,17 L2,14.0005188 L2,14.0005188 C3.10329925,14.0005188 3.99770001,13.106118 3.99770001,12.0028188 L3.99770001,11.9977 C3.99770001,10.8944008 3.10329925,10 2,10 L2,7 C2,5.8954305 2.8954305,5 4,5 Z"
},
{
  key:"categories_where_to_stay",
  icon_path:"M4,5 L20,5 C21.1045695,5 22,5.8954305 22,7 L22,10 L22,10 C20.8973631,10 20.0034993,10.8938638 20.0034993,11.9965007 L20.0034993,12.0040181 C20.0034993,13.106655 20.8973631,14.0005188 22,14.0005188 L22,17 C22,18.1045695 21.1045695,19 20,19 L4,19 C2.8954305,19 2,18.1045695 2,17 L2,14.0005188 L2,14.0005188 C3.10329925,14.0005188 3.99770001,13.106118 3.99770001,12.0028188 L3.99770001,11.9977 C3.99770001,10.8944008 3.10329925,10 2,10 L2,7 C2,5.8954305 2.8954305,5 4,5 Z"
}
];*/

export const CATEGORY_MAP_ICONS = [
{
  key:"categories_going_out",
  parent:null,
  translations:{
    'fr':"Ou sortir",
    'en':"Going out",
  }
},
{
  key:"categories_bars",
  parent:"categories_going_out",
  translations:{
    'fr':"Bars",
    'en':"Bars",
  }
},
{
  key:"categories_caves",
  parent:"categories_going_out",
  translations:{
    'fr':"Caves",
    'en':"Caves",
  }
},
{
  key:"categories_clubs",
  parent:"categories_going_out",
  translations:{
    'fr':"Clubs",
    'en':"Clubs",
  }
},
{
  key:"categories_cafes",
  parent:"categories_going_out",
  translations:{
    'fr':"Café",
    'en':"Café",
  }
},
{
  key:"categories_restaurants",
  parent:"categories_going_out",
  translations:{
    'fr':"Restaurants",
    'en':"Restaurants",
  }
},
{
  key:"categories_lounges",
  parent:"categories_going_out",
  translations:{
    'fr':"Lounges",
    'en':"Lounges",
  }
},
{
  key:"categories_event",
  parent:null,
  translations:{
    'fr':"Event",
    'en':"Event",
  }
},
{
  key:"categories_karaoke",
  parent:"categories_event",
  translations:{
    'fr':"Karaoké",
    'en':"Karaoke",
  }
},
{
  key:"categories_sponsoring",
  parent:"categories_event",
  translations:{
    'fr':"Animations",
    'en':"Sponsoring",
  }
},
{
  key:"categories_birthdays",
  parent:"categories_event",
  translations:{
    'fr':"Anniversaires",
    'en':"Birthdays",
  }
},
{
  key:"categories_concert",
  parent:"categories_event",
  translations:{
    'fr':"Concerts",
    'en':"Concerts",
  }
},
{
  key:"categories_sport",
  parent:"categories_event",
  translations:{
    'fr':"Sports",
    'en':"Sports",
  }
},
{
  key:"categories_tasting",
  parent:"categories_event",
  translations:{
    'fr':"Dégustations",
    'en':"Tastings",
  }
},
{
  key:"categories_fitness",
  parent:"categories_event",
  translations:{
    'fr':"Fitness",
    'en':"Fitness",
  }
},
{
  key:"categories_foot",
  parent:"categories_event",
  translations:{
    'fr':"Foot",
    'en':"Foot",
  }
},
{
  key:"categories_gastro",
  parent:"categories_event",
  translations:{
    'fr':"Cuisine",
    'en':"Gastronomy",
  }
},
{
  key:"categories_launches",
  parent:"categories_event",
  translations:{
    'fr':"Lancements",
    'en':"Launches",
  }
},
{
  key:"categories_dancing_nights",
  parent:"categories_event",
  translations:{
    'fr':"Soirées dansantes",
    'en':"Dancing nights",
  }
},
{
  key:"categories_private_party",
  parent:"categories_event",
  translations:{
    'fr':"Privées",
    'en':"Private",
  }
},
{
  key:"categories_vip",
  parent:"categories_event",
  translations:{
    'fr':"VIP",
    'en':"VIP",
  }
},
{
  key:"categories_where_to_stay",
  parent:null,
  translations:{
    'fr':"Ou dormir",
    'en':"Where to stay",
  }
},
{
  key:"categories_hotels",
  parent:"categories_where_to_stay",
  translations:{
    'fr':"Hotels",
    'en':"Hotels",
  }
},
{
  key:"categories_rooms",
  parent:"categories_where_to_stay",
  translations:{
    'fr':"Rooms",
    'en':"Rooms",
  }
},
{
  key:"categories_distributers",
  parent:null,
  translations:{
    'fr':"Distributeurs",
    'en':"Distributeurs",
  }
},
{
  key:"categories_petrol_stations",
  parent:"categories_distributers",
  translations:{
    'fr':"Pertrol stations",
    'en':"Pertrol stations",
  }
},
{
  key:"categories_supermarkets",
  parent:"categories_distributers",
  translations:{
    'fr':"Super markets",
    'en':"Super markets",
  }
},
];

/**
Category color configuration
*/

export const CATEGORY_COLORS = [
  {
    key:"red",
    color_code_primary:"rgba(183, 28, 44, 1)",
    color_code_tint:"rgba(183, 28, 44, 0.25)",
    translations:{
      'fr':"Rouge",
      'en':"Red",
    }
  },
  {
    key:"green",
    color_code_primary:"rgba(49, 177, 150, 1)",
    color_code_tint:"rgba(49, 177, 150, 0.25)",
    translations:{
      'fr':"Verte",
      'en':"Green",
    }
  },
  {
    key:"grey",
    color_code_primary:"rgba(36, 51, 36, 1)",
    color_code_tint:"rgba(36, 51, 36, 0.25)",
    translations:{
      'fr':"Gris",
      'en':"Grey",
    }
  },
  {
    key:"purple",
    color_code_primary:"rgba(123, 31, 102, 1)",
    color_code_tint:"rgba(123, 31, 102, .25)",
    translations:{
      'fr':"Violet",
      'en':"Purple",
    }
  }
];
