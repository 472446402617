/**
userActions
To dispatch the account actions
@functions:
getUsers,
*/

import { urlConfig, activatedModules } from '../../../_config';
import { onHttpFailure, getTableDataQueryParams } from '../../../_helpers';
import HttpRequest from '../../../_services/http/request';
const api = new HttpRequest();
export const userActions = {
  getUserList,
  getUserListWithDevice,
  getUserListByGroupId,
  getUser,
  getUserPlaceList,
  getUserEventList,
  createUser,
  updateUser,
  deleteUser,
  deleteEvent,
  deletePlace,
  getMarchandUsersAccess,
  prosDeliveryDrivers,
  deleteMarchandUsersAccess,
  getSoMatchUsersList,
  getMarchand,
  createFriendsAccess,
  getProDeliveryUser
};

/**
* getUserList
* To dispatch the get user list request
* @param  {Object} reducer [reducer with action type based on success or error response from the API]
*/

function getUserList(dispatch, onSuccess, onFailure, sortParams = {}, pageParams = {}, searchParams = {}, filterParams = {}) {
  let queryParams = getTableDataQueryParams(sortParams, pageParams, searchParams, filterParams);
  api.get(urlConfig.API_URL + 'account/users?' + queryParams).then(
    data => {
      onSuccess(data);
    },
    error => {
      onHttpFailure(error, dispatch, onFailure);
    }
  );
}
/**
* getUserListWithDevice
* To dispatch the get user list by group id request
* @param  {Object} reducer [reducer with action type based on success or error response from the API]
*/

function getUserListWithDevice(dispatch, onSuccess, onFailure, sortParams = {}, pageParams = {}, searchParams = {}, filterParams = {}) {
  let queryParams = getTableDataQueryParams(sortParams, pageParams, searchParams, filterParams);
  api.get(urlConfig.API_URL + 'account/users/with_devices?with=role;devices;city&' + queryParams).then(
    data => {
      onSuccess(data);
    },
    error => {
      onHttpFailure(error, dispatch, onFailure);
    }
  );
}

/**
* getUserListByGroupId
* To dispatch the get user list by group id request
* @param  {Object} reducer [reducer with action type based on success or error response from the API]
*/

function getUserListByGroupId(group_id, dispatch, onSuccess, onFailure, sortParams = {}, pageParams = {}, searchParams = {}, filterParams = {}) {
  let queryParams = getTableDataQueryParams(sortParams, pageParams, searchParams, filterParams);
  api.get(urlConfig.API_URL + 'account/groups/' + group_id + '/users?with=role&' + queryParams).then(
    data => {
      onSuccess(data);
    },
    error => {
      onHttpFailure(error, dispatch, onFailure);
    }
  );
}

/**
* getUser detail
* To dispatch the getUser request
* @param  {Object} reducer [reducer with action type based on success or error response from the API]
*/

function getUser(id, dispatch, onSuccess, onFailure) {
  let url = urlConfig.API_URL + 'account/users/' + id;
  if(activatedModules.includes('POS'))
    url = urlConfig.API_URL + 'account/users/' + id+'?with=pos_merchant';
  api.get(url).then(
    data => {
      onSuccess(data);
    },
    error => {
      onHttpFailure(error, dispatch, onFailure);
    }
  );
}

/**
* createUser detail
* To dispatch the updateUser request
* @param  {Object} reducer [reducer with action type based on success or error response from the API]
*/

function createUser(data, onHttpSuccess) {
  return (dispatch, _, api) => {
    dispatch(
      {
        type: 'HTTP_REQUEST',
        promise: api
          .postFormData(urlConfig.API_URL + 'oauth/register', data),
        meta: {
          onFailure: (response, getState) => {
            onHttpFailure(response, dispatch);
          },
          onSuccess: (response, getState) => {
            onHttpSuccess(response);
          }
        }
      }
    );
  };
}

/**
* updateUser detail
* To dispatch the updateUser request
* @param  {Object} reducer [reducer with action type based on success or error response from the API]
*/

function updateUser(id, data, onHttpSuccess) {
  data._method = 'PUT';
  return (dispatch, _, api) => {
    dispatch(
      {
        type: 'HTTP_REQUEST',
        promise: api
          .postFormData(urlConfig.API_URL + 'account/users/' + id, data),
        meta: {
          onFailure: (response, getState) => {
            onHttpFailure(response, dispatch);
          },
          onSuccess: (response, getState) => {
            onHttpSuccess(response);
          }
        }
      }
    );
  };
}


/**
* deleteUser
* To dispatch the deleteUser request
* @param  {Object} reducer [reducer with action type based on success or error response from the API]
*/
function deleteUser(user_id, onHttpSuccess) {
  return (dispatch, _, api) => {
    dispatch(
      {
        type: 'HTTP_REQUEST',
        promise: api
          .delete(urlConfig.API_URL + 'account/users/' + user_id),
        meta: {
          onFailure: (response, getState) => {
            onHttpFailure(response, dispatch);
          },
          onSuccess: (response, getState) => {
            onHttpSuccess(user_id, response);
          }
        }
      }
    );
  };
}


/**
* getUserPlaces detail
* To dispatch the getUserPlaces request
* @param  {Object} reducer [reducer with action type based on success or error response from the API]
*/

function getUserPlaceList(id, dispatch, onSuccess, onFailure, sortParams = {}, pageParams = {}, searchParams = {}, filterParams = {}) {
  let queryParams = getTableDataQueryParams(sortParams, pageParams, searchParams, filterParams);
  //api.get(urlConfig.API_URL + 'core/data/places?search=owner_id:' + id + '&searchFields=owner_id:=&orderBy=created_at&sortBy=desc&per_page=' + pageParams['perPage'] + '&page=' + pageParams['currentPage']).then(
  api.get(urlConfig.API_URL + 'core/data/places?' + queryParams).then(
    data => {
      onSuccess(data);
    },
    error => {
      onHttpFailure(error, dispatch, onFailure);
    }
  );
}

/**
* getSoMatch User List
* To dispatch the getSoMatchUsersList request
* @param  {Object} reducer [reducer with action type based on success or error response from the API]
*/

function getSoMatchUsersList(user_id, dispatch, onSuccess, onFailure, sortParams = {}, pageParams = {}, searchParams = {}, filterParams = {}) {
  let queryParams = getTableDataQueryParams(sortParams, pageParams, searchParams, filterParams);
  api.get(urlConfig.API_URL + 'somatch/users/' + user_id +'/friends?' + queryParams).then(
    data => {
      onSuccess(data);
    },
    error => {
      onHttpFailure(error, dispatch, onFailure);
    }
  );
}

/**
* AddSoMatch Friend Access
* To dispatch the createFriendsAccess request
* @param  {Object} reducer [reducer with action type based on success or error response from the API]
*/
function createFriendsAccess(place_id, data, onHttpSuccess) {
  return (dispatch, _, api) => {
    dispatch(
      {
        type: 'HTTP_REQUEST',
        promise: api
          .postFormData(urlConfig.API_URL + 'pos/places/' + place_id + '/accesses/', data),
        meta: {
          onFailure: (response, getState) => {
            onHttpFailure(response, dispatch);
          },
          onSuccess: (response, getState) => {
            onHttpSuccess(response);
          }
        }
      }
    );
  };
}

/**
* getMarchand Information
* To dispatch the getMarchand request
* @param  {Object} reducer [reducer with action type based on success or error response from the API]
*/

function getMarchand(id, dispatch, onSuccess, onFailure) {
  api.get(urlConfig.API_URL + 'pos/merchants?search=user_id:' + id).then(
    data => {
      onSuccess(data);
    },
    error => {
      onHttpFailure(error, dispatch, onFailure);
    }
  );
}


/**
* getMarchandUsersAccess List
* To dispatch the getMarchandUsersAccess request
* @param  {Object} reducer [reducer with action type based on success or error response from the API]
*/

function getMarchandUsersAccess(place_id, dispatch, onSuccess, onFailure, sortParams = {}, pageParams = {}, searchParams = {}, filterParams = {}) {
  let queryParams = getTableDataQueryParams(sortParams, pageParams, searchParams, filterParams);
  api.get(urlConfig.API_URL + 'pos/places/' + place_id +'/accesses?' + queryParams).then(
    data => {
      onSuccess(data);
    },
    error => {
      onHttpFailure(error, dispatch, onFailure);
    }
  );
}

/**
* getProMarchandUsersAccess List
* To dispatch the getMarchandUsersAccess request
* @param  {Object} reducer [reducer with action type based on success or error response from the API]
*/

function prosDeliveryDrivers(place_id, dispatch, onSuccess, onFailure) {
  api.get( urlConfig.API_URL_PREFIX  + 'pos/places/' + place_id +'/delivery-drivers?' ).then(
    data => {
      onSuccess(data);
    },
    error => {
      onHttpFailure(error, dispatch, onFailure);
    }
  );
}

/**
* deleteMerchand User Access
* To dispatch the deleteUserAccess request
* @param  {Object} reducer [reducer with action type based on success or error response from the API]
*/
function deleteMarchandUsersAccess(user_id, place_id, onHttpSuccess) {
  return (dispatch, _, api) => {
    dispatch(
      {
        type: 'HTTP_REQUEST',
        promise: api
          .delete(urlConfig.API_URL + 'pos/places/' + place_id + '/accesses/' + user_id),
        meta: {
          onFailure: (response, getState) => {
            onHttpFailure(response, dispatch);
          },
          onSuccess: (response, getState) => {
            onHttpSuccess(user_id, response);
          }
        }
      }
    );
  };
}

/**
* getEventList
* To dispatch the get event list request
* @param  {Object} reducer [reducer with action type based on success or error response from the API]
*/

function getUserEventList(id, dispatch, onSuccess, onFailure, sortParams = {}, pageParams = {}, searchParams = {}) {
  //let queryParams = getTableDataQueryParams(sortParams, pageParams, searchParams);
  api.get(urlConfig.API_URL + 'core/data/events?search=owner_id:' + id + '&searchFields=owner_id:=&orderBy=created_at&sortBy=desc&per_page=' + pageParams['perPage'] + '&page=' + pageParams['currentPage']).then(
    data => {
      onSuccess(data);
    },
    error => {
      onHttpFailure(error, dispatch, onFailure);
    }
  );
}

/**
* deleteEvent
* To dispatch the deleteEvent request
* @param  {Object} reducer [reducer with action type based on success or error response from the API]
*/
function deleteEvent(event_id, onHttpSuccess) {
  return (dispatch, _, api) => {
    dispatch(
      {
        type: 'HTTP_REQUEST',
        promise: api
          .delete(urlConfig.API_URL + 'core/data/events/' + event_id),
        meta: {
          onFailure: (response, getState) => {
            onHttpFailure(response, dispatch);
          },
          onSuccess: (response, getState) => {
            onHttpSuccess(event_id, response);
          }
        }
      }
    );
  };
}

/**
* deletePlace
* To dispatch the deletePlace request
* @param  {Object} reducer [reducer with action type based on success or error response from the API]
*/
function deletePlace(place_id, onHttpSuccess) {
  return (dispatch, _, api) => {
    dispatch(
      {
        type: 'HTTP_REQUEST',
        promise: api
          .delete(urlConfig.API_URL + 'core/data/places/' + place_id),
        meta: {
          onFailure: (response, getState) => {
            onHttpFailure(response, dispatch);
          },
          onSuccess: (response, getState) => {
            onHttpSuccess(place_id, response);
          }
        }
      }
    );
  };
}

/**
* getProDeliveryUser Information
* To dispatch the getProDeliveryUser request
* @param  {Object} reducer [reducer with action type based on success or error response from the API]
*/

function getProDeliveryUser(id, dispatch, onSuccess, onFailure) {
  api.get(urlConfig.API_URL_PREFIX + 'account/profile/' + id).then(
    data => {
      onSuccess(data);
    },
    error => {
      onHttpFailure(error, dispatch, onFailure);
    }
  );
}
