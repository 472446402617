// Core react libs
import React, {Component} from 'react';
import { connect } from 'react-redux';

// Translation
import { getTranslate, getActiveLanguage } from 'react-localize-redux';

// Local custom components/libs
import { groupActions, userActions } from '../../../../../Account/actions';
import { HttpMessageBar } from '../../../../../../_components/notifications/HttpMessageBar';
import {
   Loader
 } from '../../../../../../_components/layout'

import {
   InputSelectWithLabel
 } from '../../../../../../_components/form'

/** Assigned To Block - To assign a user to a ticket **/
class AssignedToBlock extends Component {

  /**
  * To setup the inital state
  * @param {Object} props
  */
  constructor(props) {
    super(props);

    this.state = {
      users:[],
      loaded:false
    };

  }

  /**
  * when component mounted
  */
  componentDidMount() {
    // To make synch API calls for loading data
    this.loadData();
  }


  /**
  * When component updated
  * @param {Object} prevProps
  */
  componentDidUpdate(prevProps) {
     if(prevProps.activeLanguage  !== this.props.activeLanguage){
        this.loadData();
      }
  }


  /**
  * To load the data
  */
  loadData=()=>{
    groupActions.getGroupList(this.props.dispatch,this.onGroupDataLoadSuccess,this.onDataLoadFailure);
  }



  /**
  * When groups data is loaded successfully
  * @param {Object} response
  */
  onGroupDataLoadSuccess=(response)=>{
    let groups = response.data;
    let group_id = groups.filter(group => group.slug === 'owners')[0]['id'];
    console.log('group_id',group_id);
    if(group_id){
      userActions.getUserListByGroupId(group_id, this.props.dispatch, this.onUsersDataLoadSuccess, this.onDataLoadFailure);
    }
  }

  /**
  * When users data is loaded successfully
  * @param {Object} response
  */
  onUsersDataLoadSuccess=(response)=>{
    console.log('response',response);
    let users = response.data;
    users.map(user => {
      user.name = user.first_name+" "+user.last_name;
      return true;
    });
      console.log('users',users);
    this.setState({
      users: users,
      loaded: true
    })
  }

  /**
  * when http request failed
  * @param {Object} error
  */
  onDataLoadFailure=(response)=>{
    let error = response.json;
    let alert ={
      'type':'error',
      'message':error.message?error.message:'',
      'description':error.description?error.description:''
    }
    this.setState({
      error:alert,
      loaded:true
    });
  }

  /**
  * getFieldErrorMessages
  * To get the error messages for each field
  * @param [field]
  * @param  {Object}[errorBag] - error messages
  */
  getFieldErrorMessages =(field)=>{
    let httpErrorsExist = false;
    if(this.props.alert && this.props.alert.errors){
      let httpFieldErrors = this.props.alert.errors;
      if(httpFieldErrors && httpFieldErrors[field] && httpFieldErrors[field].length > 0){
        httpErrorsExist = true;
        return httpFieldErrors[field];
      }
      else
        httpErrorsExist = false;
    }
    if(!httpErrorsExist)
      return this.props.errorBag.field_validation_messages;
  }

  /**
  * To render the component
  * @returns  {Object} HTML element
  */
  render() {
    const {
      ticket,
      handleChange,
      translate
    } = this.props;

    const {
      users,
      loaded,
      error
    } = this.state;

    return (
      <div>
          <HttpMessageBar alert={error} />
            {!loaded &&
              <div><Loader /></div>
            }
            {loaded &&
              <InputSelectWithLabel
                name = "assigned_id"
                id = "assigned_id"
                options={users}
                value = {ticket.assigned_id}
                label={translate("Assigned To")}
                onChange = {handleChange}
                errorMessages = {this.getFieldErrorMessages('assigned_id')}
                required={false}
              />
            }
    </div>
    );
  }
}

function mapStateToProps(state) {
    return {
        translate: getTranslate(state.localize),
        loading: state.http_request.sending,
        activeLanguage: getActiveLanguage(state.localize).code
    };
}

AssignedToBlock = connect(mapStateToProps)(AssignedToBlock);
export {AssignedToBlock};
