/**
* validateFields
* To validate the input fields for different validation rules
* @param ['validationRules', 'fields']
* @param  {Object} errorBag [errorBag with validation error messages]
*/

import moment from 'moment';



export function validateFields(validationRules, fields, translate) {
  let errorBag = {'field_validation_messages':[]};
  Object.keys(validationRules).map((fieldName) => {
    const field = fields.filter(field => (field.key === fieldName));
    const value = field[0].value;
    const currentFieldRule = validationRules[fieldName].rule;
    let rules = [currentFieldRule]
    if(typeof currentFieldRule === 'string')
      rules = currentFieldRule.split('|');

    // eslint-disable-next-line
    rules.map((ruleName) => {
        if(ruleName === 'required'){
          const message_already_exists = errorBag.field_validation_messages.filter(message => (message.field === fieldName));
          if(message_already_exists.length <= 0){
            if(value === '' || value === '0'|| value === 0 || value === undefined || value === null){
                errorBag.field_validation_messages.push({'type':'error','field':fieldName,'code':'required','description':translate(validationRules[fieldName].label +' is required')});
            }
          }
        }
        else if(ruleName === 'email'){ //email input validation
          const message_already_exists = errorBag.field_validation_messages.filter(message => (message.field === fieldName));
          if(message_already_exists.length <= 0){
            let emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
            if(!emailValid){
                errorBag.field_validation_messages.push({'type':'error','field':fieldName,'code':'email_invalid','description':translate('Email is invalid')});
            }
          }
        }
        else if(ruleName === 'urlkey'){ //url input validation
          const message_already_exists = errorBag.field_validation_messages.filter(message => (message.field === fieldName));
          if(message_already_exists.length <= 0){
            let regex = /^[a-zA-Z0-9-_]+$/;
            let urlkeyCheck = value.search(regex);
            if(urlkeyCheck === -1){
                errorBag.field_validation_messages.push({'type':'error','field':fieldName,'code':'urlkey_invalid','description':translate('Url key is invalid')});
            }
          }
        }
        else if ((typeof ruleName === 'string') && ruleName.search(/^min:*/) !== -1){ //minimum characters
          const message_already_exists = errorBag.field_validation_messages.filter(message => (message.field === fieldName));
          if(message_already_exists.length <= 0){
            const requiredLength = ruleName.split(':')[1];
            const passwordCountValid = value.length >= requiredLength;
            if(!passwordCountValid){
                errorBag.field_validation_messages.push({'type':'error','field':fieldName,'code':'min','description':translate('Password should have minimum '+requiredLength+' characters')})
            }
          }
        }
        if(ruleName.condition && ruleName.field_type === 'date'){// comparison

          const compared_with_field = fields.filter(field => (field.key === ruleName.comparison_field));
          const compared_with_value = compared_with_field[0].value;
          let start_date = moment(value);
          let end_date = moment(compared_with_value);
          const message_already_exists = errorBag.field_validation_messages.filter(message => (message.field === fieldName));
          if(message_already_exists.length <= 0){
            if(ruleName.condition === '<' && start_date.diff(end_date) >= 0){
                errorBag.field_validation_messages.push({'type':'error','field':fieldName,'code':'greater','description':translate(validationRules[fieldName].message)});
            }
            else if (ruleName.condition === '>' && start_date.diff(end_date) < 0) {
              errorBag.field_validation_messages.push({'type':'error','field':fieldName,'code':'lesser','description':translate(validationRules[fieldName].message)});
            }
          }
        }
        return false;
    });
    return false;
  });

  return errorBag;
}

export function validateForm(validationRules, formData, translate){
  let fields = [];
  Object.keys(validationRules).map((fieldName) => {
    let field = {};
    field['key'] = fieldName;
    if(fieldName.indexOf('translation') > -1){
      if(fieldName.indexOf('@') > -1){
        let suffix = fieldName.split('@')[1];
        let fieldnames = suffix.split('_');
        field['value'] = formData['translations'][fieldnames[1]][fieldnames[2]];
      }
      else{
        let fieldnames = fieldName.split('_');
          let suffix = '';
          for(var i=2;i<fieldnames.length;i++){
            suffix += fieldnames[i];
            if(i < (fieldnames.length -1))
            suffix += '_';
          }
        field['value'] = formData['translations'][fieldnames[1]][suffix];
      }
    }
    else if(fieldName.indexOf('@') > -1){
      let fieldnames = fieldName.split('@');
      field['value'] = formData[fieldnames[1]];
    }
    else{
      field['value'] = formData[fieldName];
    }
    fields.push(field);
    return true;
  });
  let errorBag = validateFields(validationRules, fields, translate);
  return {
        errorBag:errorBag
  };
}


// To check if field error exists for this language tab
export function langTabHasError(currentLanguage, fields, errorMessages){
  let errorExist = false;
  if(errorMessages && errorMessages.length > 0){
    errorMessages.map((error) => {
      let field_exists = fields.filter(field =>{
        let prefix = (field.prefix !== undefined)?field.prefix:'';
        return (prefix+'translation_'+currentLanguage+'_'+field.name === error.field)
      }
      );
      if(field_exists.length > 0)
       errorExist = true;
      return true;
    });
  }

  return errorExist;
}
