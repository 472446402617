/**
DefaultRoute
Route component to redirect to base url when url key doesn't match
@functions:DefaultRoute
*/

// Core react dependencies
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

// Translation
import { getActiveLanguage } from 'react-localize-redux';

// Custom module dependencies
import { urlConfig } from '../../_config';

const DefaultRoute = ({ activeLanguage, ...rest }) => (
    <Route {...rest} render={props => (
      <Redirect to={{ pathname: `${urlConfig.BASE_URL}/${activeLanguage}`, state: { from: props.location } }} />
    )} />
)


const mapStateToProps = state => (
  {
    activeLanguage: getActiveLanguage(state.localize).code,
  }
);

const connectedDefaultRoute = connect(mapStateToProps)(DefaultRoute)
export { connectedDefaultRoute as DefaultRoute };
