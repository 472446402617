/**
Configuring tables
@functions:
getTableOptions: options for data table
*/
import React from 'react';
import { Input, Label } from 'reactstrap';
import { SearchField } from 'react-bootstrap-table';

const TableSizePerPage = 15;
const SizePerPageList = [
  {
  text: '15', value: 15
  },
  {
    text: '50', value: 50
  },
  {
    text: 'ALL', value: 0
  }
];
class TableConfig extends React.Component {
    constructor(props) {
        super(props);
        this.sizePerPageList = [
          {
          text: '3', value: 3
          },
          {
            text: '5', value: 5
          },
          {
            text: this.props.translate('All'), value: (this.props.totalRows? this.props.totalRows: 20)
          }
        ];
    }
    createCustomButtonGroup = props => {
    /**
     *  This function only pass one argument, is props object has following properties
     *  {
     *    exportCSVBtn,  // export CSV button JSX
     *    insertBtn,  // insert button JSX
     *    deleteBtn,  // delete button JSX
     *    showSelectedOnlyBtn  // show selected button JSX
     *  }
     ***/
    return (
      <div className="btn-group btn-group-md" role="group">
        {props.insertBtn}
      </div>
    );
  }

    createCustomSearchField = (props) => {
      return (
        <SearchField
            className='table-search'
            placeholder={this.props.translate('Search')}/>
      );
    }
    renderSizePerPageDropDown = (props) => {
      return (
        <div>
          <Label>{this.props.translate('Show')} &nbsp;
          <Input className="table-size-dropdown" type="select" name="size" id="id" onChange={(e) => props.changeSizePerPage(e.target.value)} >
          {
            this.sizePerPageList.map((size, idx) => {
              const isActive = (size.value === props.currSizePerPage) ? 'active' : null;
              if(isActive)
              return (
                  <option key={idx} value={size.value} selected >{size.text}</option>
              );
              else
              return (
                  <option key={idx} value={size.value} >{size.text}</option>
              );
            })

          }
          </Input>
          &nbsp; {this.props.translate('entries')}
          </Label>
        </div>
      );
    }

    getOptions = () => {
      return{
        sortIndicator: true,
        hidePageListOnlyOnePage: true,
        alwaysShowAllBtns: false,
        withFirstAndLast: false,
        searchField: this.customSearchField,
        sizePerPageDropDown: this.renderSizePerPageDropDown,
        defaultSortName: this.props.defaultSortName,
        defaultSortOrder: this.props.defaultSortOrder,
        insertBtn: this.props.insertBtn,
        noDataText: this.props.translate('No results found'),
        withoutNoDataText: false,
        btnGroup: this.createCustomButtonGroup,
        sortName: this.props.sortName,
        sortOrder: this.props.sortOrder,
        sizePerPage: this.props.sizePerPage,
        page: this.props.page,
        onSortChange: this.props.onSortChange,
        onSearchChange: this.props.onSearchChange,
        onPageChange: this.props.onPageChange,
        onSizePerPageList: this.props.onSizePerPageList
      }
    }

}
export { TableConfig, TableSizePerPage, SizePerPageList };
